import { css } from '@emotion/react';
import { theme } from './theme';

export default css`
  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: ${theme.fonts.body};
    font-weight: 400;
    color: ${theme.colors.black};
    background: ${theme.colors['light-grey']};
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
