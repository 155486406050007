import { CheckIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Link } from '@chakra-ui/react';

const PlanLarge = ({ plan, stripeHandler, disableBtn }) => {
  const commonColumnStyles = {
    flexDir: 'column',
    w: '100%',
    maxW: '337px',
    flex: '1',
  };

  const columnClass = {
    ...commonColumnStyles,
    ...(plan.title === 'Growth' && {
      border: '2px solid #F7573E',
      borderRadius: '4px',
      bgColor: '#abcffe4d',
    }),
    ...(plan.title !== 'Growth' &&
      plan.title !== 'Startup' && {
      borderRight: '1px solid #ced7e5',
      borderTop: '1px solid #ced7e5',
      borderBottom: '1px solid #ced7e5',
    }),
    ...(plan.title === 'Startup' && {
      borderTop: '1px solid #ced7e5',
      borderBottom: '1px solid #ced7e5',
    }),
  };

  const transition = {
    ':hover': {
      color: 'white',
      bgColor: '#f7573e',
      textDecoration: 'none',
    },
  };

  const wordSpacing = {
    wordSpacing: '0.5rem',
  };

  const box = {
    borderBottom: '1px solid #ced7e5',
    textAlign: 'center',
    minH: '53px',
    color: '#086cfc',
    mb: '-1px',
    pt: '16px',
    pb: '16px',
    fontSize: '18px',
  };

  const customPlanBox =
    plan.title === 'Custom Plan' ? (
      <Box borderBottomRightRadius="4px" sx={box}>
        <CheckIcon mr={2} />
      </Box>
    ) : null;

  return (
    <Flex
      sx={columnClass}
      {...(plan.title === 'Custom Plan'
        ? { borderBottomRightRadius: '4px', borderTopRightRadius: '4px' }
        : {})}
    >
      <Box
        minH="214px"
        textAlign="center"
        pt="32px"
        fontSize="24px"
        fontWeight="500"
        lineHeight="29px"
        borderBottom="1px solid #ced7e5"
      >
        <Box fontWeight="700" color="#283442" sx={wordSpacing}>
          {plan.title}
        </Box>
        <Box
          mt="12px"
          color="#475464"
          sx={
            plan.title === 'Custom Plan'
              ? {
                color: 'transparent',
              }
              : {}
          }
        >
          {plan.price}
        </Box>
        <Box fontSize="18px" fontWeight="400" mb="24px">
          {plan.requests}
        </Box>
        <Box>
          {plan.title === 'Custom Plan' ? (
            <Link
              href="mailto:hello@supernovaapi.com?subject=Custom%20plan"
              px="15px"
              py="9px"
              maxH="40px"
              fontSize="18px"
              fontFamily="Consolas, sans-serif"
              letterSpacing="0.06em"
              fontWeight="400"
              borderRadius="2px"
              color="#f7573e"
              border="1px solid #f7573e"
              sx={{
                ...transition,
              }}
            >
              {plan.btnText}
            </Link>
          ) : (
            <Button
              isDisabled={disableBtn}
              bgColor="rgb(250,251,254)"
              px="15px"
              py="9px"
              maxH="40px"
              fontSize="18px"
              fontFamily="Consolas, sans-serif"
              letterSpacing="0.06em"
              fontWeight="400"
              borderRadius="2px"
              color="#f7573e"
              border="1px solid #f7573e"
              sx={{
                ...transition,
                ...(plan.title === 'Growth'
                  ? {
                    color: 'white',
                    bgColor: '#f7573e',
                  }
                  : {}),
              }}
              onClick={() => {
                stripeHandler(plan.id);
              }}
            >
              {plan.btnText}
            </Button>
          )}
        </Box>
      </Box>
      <Box sx={box}>
        <CheckIcon mr={2} />
      </Box>
      {customPlanBox}
    </Flex>
  );
};

export default PlanLarge;
