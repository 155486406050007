import { Box, Link as CLink, Heading, Link, Text } from '@chakra-ui/layout';
import { Button, Flex, FormControl, FormLabel, Input } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import PasswordInput from '../../components/PasswordInput';
import SEO from '../../components/SEO';
import { baseUrl, useAuth } from '../../context/Auth';
import { setSubscriptionStatus } from '../../hooks/setSubscriptionStatus';
import { supabase } from '../../supabaseClient';
import AuthLayout from './AuthLayout';
import { Message } from './RegisterPage';

const LoginPage = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const rememberMeRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState<Message>({ type: '', text: '' });
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const history = useNavigate();

  const { signIn, updateSubscriptionStatus } = useAuth();

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'USER_UPDATED') {
        setMessage({ type: 'success', text: 'Account successfully verified' });
      }
    });
  });

  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const email = emailRef?.current?.value;
    const password = passwordRef?.current?.value;
    const payload = { email, password };
    updateSubscriptionStatus(null)

    if (Object.values(payload).every((el) => !!el)) {
      setIsLoggingIn(true);

      const { error, data } = await signIn(payload);
      if (error) {
        setIsLoggingIn(false);
        setMessage({ type: 'error', text: error.message });
        return
      }
      try {
        const userStripeObj = await supabase
          .from('user_stripe')
          .select()
          .eq('id', data?.user?.id)
        if (userStripeObj?.data[0]?.monthly_credits === 0) {
          setMessage({ type: 'error', text: 'Something went wrong' });
          setIsLoggingIn(false);
          return;
        }
      } catch (error) {
        setMessage({ type: 'error', text: 'Something went wrong' });
      }

      setIsLoggingIn(false);
      if (data && data?.user && data?.user?.id) {
        await setSubscriptionStatus(data?.user?.id, updateSubscriptionStatus)
        history('/dashboard');
      }
    } else {
      setMessage({
        type: 'error',
        text: 'You must provide values for all the fields',
      });
    }
  };



  return (
    <>
      <SEO
        title="Login to your account"
        htmlAttributes={{ lang: 'en' }}
        link={[
          {
            rel: 'canonical',
            href: `${baseUrl}/login`,
          },
        ]}
        meta={[
          {
            content: 'Login',
            property: 'og:title',
          },
        ]}
      />
      <AuthLayout>
        <Card py="4rem" w="full" rounded="3px" h="600px">
          <Heading textAlign="center" size="lg">
            Login
          </Heading>
          <Text textAlign="center" mb="3rem">
            Login to your account
          </Text>

          <Box as="form" maxW="550px" mx="auto">
            {message.type === 'error' && (
              <Flex
                w="full"
                mb="0.5rem"
                borderWidth="1px"
                rounded="md"
                borderColor="alert"
                bg="red.50"
                p="1rem"
              >
                <Text fontSize="sm">{message.text}</Text>
              </Flex>
            )}
            {message.type === 'success' && (
              <Flex
                w="full"
                mb="0.5rem"
                borderWidth="1px"
                rounded="md"
                borderColor="sea-green"
                bg="green.50"
                p="1rem"
              >
                <Text fontSize="sm">{message.text}</Text>
              </Flex>
            )}

            <Flex>
              <FormControl my="0.8rem" mx="0.5rem" id="email">
                <FormLabel>Company Email</FormLabel>
                <Input ref={emailRef} rounded="md" type="text" id="email" />
              </FormControl>
            </Flex>

            <Flex>
              <FormControl my="0.8rem" mx="0.5rem" id="password">
                <FormLabel>Password</FormLabel>
                <PasswordInput ref={passwordRef} rounded="md" id="password" />
                <Flex align="center" justify="space-between" mt="0.5rem">
                  <Flex>
                    {/* <Checkbox ref={rememberMeRef} mr="0.5rem" id="rememberMe" />{' '}
                    <FormLabel
                      m={0}
                      fontWeight="normal"
                      fontSize="sm"
                      htmlFor="rememberMe"
                    >
                      Remember me
                    </FormLabel> */}
                  </Flex>

                  <CLink as={Link} href="/forgot-password" fontSize="sm">
                    Forgot password?
                  </CLink>
                </Flex>
              </FormControl>
            </Flex>

            <Flex py="1rem" px="0.5rem">
              <Button
                py="1.5rem"
                type="submit"
                width="full"
                color="white"
                bg="orange-soda"
                _hover={{ bg: 'orange-soda' }}
                rounded="3px"
                onClick={handleSubmit}
                isLoading={isLoggingIn}
              >
                Login
              </Button>
            </Flex>

            {/* <Flex px="0.5rem" justify="center" w="full">
              <Text fontSize="sm" textAlign="center">
                Don't have an account?{' '}
                <CLink
                  as={Link}
                  href="/register"
                  textAlign="center"
                  color="orange-soda"
                >
                  Register
                </CLink>
              </Text>
            </Flex> */}
          </Box>
        </Card>
      </AuthLayout>
    </>
  );
};

export default LoginPage;
