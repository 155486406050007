import {
  Button,
  Spinner,
  Box,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Flex, Heading, Text } from '@chakra-ui/layout';
import Card from '../../components/Card';
import React, { useEffect, useState } from 'react';
import SEO from '../../components/SEO';
import { baseUrl } from '../../context/Auth';
import { Profile } from '../../utils';
import { supabase } from '../../supabaseClient';
import AccountSettingsLayout from './AccountSettingsLayout';
import withAuth from '../Auth/withAuth';

const OverageBilling = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [user, setUser] = useState<Profile>(null);
  const [accessToken, setAccessToken] = useState<any>(null);
  const [userStripeInfo, setUserStripeInfo] = useState<any>({});

  const [status, setStatus] = useState({
    loading: false,
  });

  const [enableDisableOpLoading, setEnableDisableOpLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setAccessToken(session?.access_token ?? null);
      setUser(session?.user ?? null);
    })();
  }, []);

  useEffect(() => {
    if (user) {
      (async () => {
        try {
          setStatus({
            loading: true,
          });
          const { data, error } = await supabase
            .from('user_stripe')
            .select()
            .eq('id', user.id)
            .single();

          if (error) {
            throw error;
          }

          const overageBillingStatus = data.unlimited_usage
            ? 'Enabled'
            : 'Disabled';

          const overageBillingButtonText = data.unlimited_usage
            ? 'Disable'
            : 'Enable';

          setUserStripeInfo({
            ...data,
            overageBillingStatus,
            overageBillingButtonText,
          });

          setStatus({
            loading: false,
          });
        } catch (error) {
          setStatus({
            loading: false,
          });

          toast({
            title: error.message || 'Something went wrong',
            position: 'top',
            isClosable: true,
            status: 'error',
          });
        }
      })();
    } else {
      setStatus({
        loading: true,
      });
    }
  }, [user?.id]);

  const handleOverageBilling = async (event, action: string) => {
    event.preventDefault();
    try {
      if (action === 'Enable') {
        setEnableDisableOpLoading(true);
        // Call API to enable overage billing

        const response = await fetch(
          `https://u.supernova-493.workers.dev/api/overage-billing`,
          {
            method: 'POST',
            headers: {
              Authorization: accessToken,
              'Content-Type': 'text/plain',
            },
          }
        );

        const parsedResp = await response.json();

        if (response.status !== 200) {
          throw new Error(parsedResp.message);
        }

        const overageBillingButtonText =
          parsedResp.status === 'Disabled' ? 'Enable' : 'Disable';

        setUserStripeInfo((prev) => ({
          ...prev,
          overageBillingStatus: parsedResp.status,
          overageBillingButtonText,
        }));

        setEnableDisableOpLoading(false);
        // set updated state
      } else if (action === 'Disable') {
        // Open modal
        onOpen();
      }
    } catch (error) {
      const errorMessages = error.message || 'Something went wrong';

      setEnableDisableOpLoading(false);
      toast({
        title: errorMessages.includes('JWT')
          ? 'Session expired, please refresh the page'
          : errorMessages,
        position: 'top',
        isClosable: true,
        status: 'error',
      });
    }
  };

  const handleDisableOverageBilling = async (event) => {
    event.preventDefault();
    try {
      setEnableDisableOpLoading(true);
      // call api with access token
      const response = await fetch(
        `https://u.supernova-493.workers.dev/api/overage-billing`,
        {
          method: 'POST',
          headers: {
            Authorization: accessToken,
            'Content-Type': 'text/plain',
          },
        }
      );

      const parsedResp = await response.json();

      if (response.status !== 200) {
        throw new Error(parsedResp.message);
      }

      const overageBillingButtonText =
        parsedResp.status === 'Disabled' ? 'Enable' : 'Disable';

      setUserStripeInfo((prev) => ({
        ...prev,
        overageBillingStatus: parsedResp.status,
        overageBillingButtonText,
      }));

      setEnableDisableOpLoading(false);
      onClose();
      // set updated state
    } catch (error) {
      const errorMessages = error.message || 'Something went wrong';

      setEnableDisableOpLoading(false);
      toast({
        title: errorMessages.includes('JWT')
          ? 'Session expired, please refresh the page'
          : errorMessages,
        position: 'top',
        isClosable: true,
        status: 'error',
      });
    }
  };

  return (
    <>
      <SEO
        title="Overage Billing"
        htmlAttributes={{ lang: 'en' }}
        link={[
          {
            rel: 'canonical',
            href: `${baseUrl}/overagebilling`,
          },
        ]}
        meta={[
          {
            content: 'Account settings - Overage Billing',
            property: 'og:title',
          },
        ]}
      />
      <AccountSettingsLayout page="overagebilling">
        <Card py="4rem" flexGrow={1}>
          <Modal onClose={onClose} size={'md'} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontFamily={'monospace'} mt={'10px'} mx={'1.5'}>
                Disable Overage Billing
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody mx={'1.5'}>
                <Text fontSize="sm">
                  Please note if you have consumed credits, you'll be charged
                  immediately for them and then overage will be disabled
                </Text>

                <Button
                  py="1.5rem"
                  color="white"
                  width={'full'}
                  my="35px"
                  fontFamily="heading"
                  bg="orange-soda"
                  fontSize={'18px'}
                  _hover={{ bg: 'orange-soda' }}
                  rounded="3px"
                  isLoading={enableDisableOpLoading}
                  onClick={(e) => handleDisableOverageBilling(e)}
                >
                  I agree, disable overage billing
                </Button>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Heading textAlign="center" fontSize="3xl" mb="3rem" mx="auto">
            Overage Billing
          </Heading>
          <Flex as="form" mx="auto" maxW="500px" flexDir="column">
            {status.loading ? (
              <Flex justify="center" align="center" h="100%" p={8}>
                <Spinner size="xl" />
              </Flex>
            ) : (
              <>
                <Box display={'flex'} flexDirection={'row'} alignItems="center">
                  <Text fontSize="sm">Your overage billing is: </Text>
                  <Box bgColor="#EBF3FF" borderRadius="full" p={2} ml={2}>
                    <Text fontSize="sm" color={'#036EFF'}>
                      {userStripeInfo.overageBillingStatus}
                    </Text>
                  </Box>
                </Box>
                <Button
                  py="1.5rem"
                  color="white"
                  width={'full'}
                  mt="40px"
                  fontFamily="heading"
                  bg="orange-soda"
                  fontSize={'18px'}
                  _hover={{ bg: 'orange-soda' }}
                  rounded="3px"
                  onClick={(e) =>
                    handleOverageBilling(
                      e,
                      userStripeInfo.overageBillingButtonText
                    )
                  }
                  isLoading={enableDisableOpLoading}
                >
                  {userStripeInfo.overageBillingButtonText} Overage Billing
                </Button>
              </>
            )}
          </Flex>
        </Card>
      </AccountSettingsLayout>
    </>
  );
};

export default withAuth(OverageBilling);
