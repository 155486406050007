import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import EmptyState from '../../../components/EmptyState';
import { ChartData } from '../../../utils';

interface Props {
  data: ChartData[];
  isLoading: boolean;
  xAxisLabel: string;
  isResponseEmpty: boolean;
}

const RequestChart = ({
  data = [],
  isLoading,
  xAxisLabel,
  isResponseEmpty,
}: Props) => {
  return (
    <Box
      mt={8}
      h={['350px', '350px', '400px']}
      w="full"
      width={'100%'}
      minW="700px"
    >
      {isLoading && (
        <Flex justify="center" align="center" h="100%" p={8}>
          <Spinner size="xl" />
        </Flex>
      )}
      {!isLoading && isResponseEmpty && (
        <Flex justify="center" align="center" w="100%" h="300px">
          <EmptyState />
        </Flex>
      )}

      {!isLoading && !isResponseEmpty && (
        <ResponsiveBar
          data={data}
          keys={['Successful', 'Failed']}
          indexBy="day"
          margin={{ top: 50, right: 0, bottom: 50, left: 60 }}
          padding={0.3}
          groupMode="grouped"
          colors={['#006CFF', '#FA6D76']}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisBottom={{
            tickSize: 4,
            tickPadding: 4,
            tickRotation: 0,
            legend:
              xAxisLabel !== 'last_month' && xAxisLabel !== 'current_month'
                ? `Last ${xAxisLabel} days`
                : xAxisLabel === 'last_month'
                ? 'Last month'
                : 'Current month',
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          axisLeft={{
            tickSize: 4,
            tickPadding: 4,
            tickRotation: 0,
            legend: 'Credits used',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top-right',
              direction: 'row',
              translateY: -50,
              // justify: false,
              // translateX: 120,
              // translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          tooltip={(p) => {
            return (
              <Box
                style={{
                  padding: 12,
                  background: '#fff',
                }}
                borderWidth="1px"
                boxShadow="0 0 3px rgba(0, 0, 0, 0.2)"
              >
                <Text fontSize="sm" fontWeight="500">
                  {p.data.date}
                </Text>
                <Text color={p.color} fontSize="sm">
                  {p.id}: {data[p.index][`${p.id}RequestsCount`]}
                </Text>
                <Text color={p.color} fontSize="sm">
                  Credits: {p.value}
                </Text>
              </Box>
            );
          }}
          role="application"
        />
      )}
    </Box>
  );
};

export default RequestChart;
