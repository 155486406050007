import { Box, BoxProps } from '@chakra-ui/layout';
import classNames from 'classnames';
import React from 'react';
import { BaseHtmlProps, BaseProps } from '../../utils';
import { Backdrop } from './Modal.styled';
import Portal from './Portal';

export type ModalProps = BaseProps &
  BaseHtmlProps<HTMLDivElement> &
  BoxProps & {
    open: boolean;
    onClose: () => void;
    locked?: boolean;
    children: React.ReactNode;
  };

export default function Modal(props: ModalProps) {
  const [active, setActive] = React.useState(false);
  const { open, onClose, locked, className, ...rest } = props;
  const backdrop = React.useRef<HTMLDivElement>(null);

  const classes = classNames('modal-content', className);

  React.useEffect(() => {
    const { current } = backdrop;

    const transitionEnd = () => setActive(open);

    function clickHandler(this: HTMLDivElement, e: MouseEvent) {
      if (!locked && e.target === current) onClose();
    }

    if (current) {
      current.addEventListener('transitionend', transitionEnd);
      current.addEventListener('click', clickHandler);
    }

    if (open) {
      window.setTimeout(() => {
        setActive(open);
        // document.querySelector('#root')?.setAttribute('inert', 'true');
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener('transitionend', transitionEnd);
        current.removeEventListener('click', clickHandler);
      }

      // document.querySelector('#root')?.removeAttribute('inert');
    };
  }, [open, locked, onClose]);

  if (!open && !active) {
    return null;
  }

  return (
    <Portal className="modal-portal">
      <Backdrop
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        backdropFilter="blur(1px)"
        opacity={0}
        transition="all 20ms cubic-bezier(0.4, 0, 0.2, 1)"
        transitionDelay="0ms"
        bg="rgba(126, 121, 121, 0.3)"
        justify="center"
        align="center"
        ref={backdrop}
        className={active && open ? 'active' : ''}
      >
        <Box
          transform="translateY(100px)"
          transition="all 20ms cubic-bezier(0.4, 0, 0.2, 1)"
          rounded="base"
          bg="white"
          opacity={0}
          position="relative"
          boxShadow="0 0 4px rgba(0, 0, 0, 0.2)"
          maxW="800px"
          maxH="500px"
          h="100%"
          flex={1}
          className={classes}
          {...rest}
        >
          {props.children}
        </Box>
      </Backdrop>
    </Portal>
  );
}
