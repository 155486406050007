import * as React from 'react';

function ExclamationIcon({
  fill = '#1A1A1A',
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 35c0-.657.155-1.307.457-1.913a5.048 5.048 0 011.3-1.622 6.194 6.194 0 011.947-1.084A7.054 7.054 0 016 30c.788 0 1.568.128 2.296.38.728.251 1.39.62 1.947 1.084.557.464.999 1.015 1.3 1.622.302.606.457 1.256.457 1.913 0 1.326-.632 2.598-1.757 3.536C9.117 39.473 7.59 40 6 40c-1.591 0-3.117-.527-4.243-1.464C.632 37.598 0 36.326 0 35zM.585 4.976A3.818 3.818 0 01.83 3.11c.237-.6.623-1.153 1.132-1.622a5.507 5.507 0 011.824-1.1A6.368 6.368 0 015.988 0c.759 0 1.509.132 2.202.389a5.508 5.508 0 011.824 1.1 4.425 4.425 0 011.132 1.622c.237.6.32 1.236.245 1.865L9.288 22.513c-.078.684-.46 1.32-1.07 1.781-.61.463-1.405.719-2.23.719s-1.62-.256-2.23-.719c-.61-.462-.992-1.097-1.07-1.781L.588 4.976H.585z"
        fill={fill}
      />
    </svg>
  );
}

export default ExclamationIcon;
