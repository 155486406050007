import React, { useCallback, useEffect, useState } from 'react';
import Card from '../../../components/Card';
import { Flex, Grid, Heading, Text } from '@chakra-ui/layout';
import { Select, Spinner, Tooltip, Box, Link } from '@chakra-ui/react';
import RequestChart from './RequestChart';
import {
  ChartData,
  constructAPILogChartData,
  getDaysInMonth,
  ActiveUser,
  subtractDays,
} from '../../../utils';
import { PostgrestResponse } from '@supabase/supabase-js';
import { definitions, supabase } from '../../../supabaseClient';
import { WarningIcon, ExternalLinkIcon } from '@chakra-ui/icons';
type FilterParam = number | 'current_month' | 'last_month';

interface CreditProps {
  activeUser: ActiveUser;
  showDetails: boolean;
}

const Usage = ({ showDetails, activeUser }: CreditProps) => {
  const [cardData, setCardData] = useState({
    successRequests: 0,
    pendingRequests: 0,
    failedRequests: 0,
    totalRequests: 0,
    emptyRequests: 0,
  });
  const [filterDays, setFilterDays] = useState<FilterParam>(30);

  const [isLoadingAPILogData, setIsLoadingAPILogData] = useState(false);
  const [isLoadingAPIChartLogData, setIsLoadingAPIChartLogData] =
    useState(false);

  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [isResponseEmpty, setIsResponseEmpty] = useState(false);
  const loadAPILogData = useCallback(async (filterParam: FilterParam = 30) => {
    try {
      let response: PostgrestResponse<definitions['api_logs']>;
      setChartData([]);
      setIsLoadingAPILogData(true);
      const data = [];

      const { data: supabaseRes, error } = await supabase
        .from('user_stripe')
        .select('successful_calls, failed_calls, empty_calls')
        .eq('id', activeUser.id)
        .single();

      if (error) {
        throw error;
      }

      if (supabaseRes && Object.keys(supabaseRes).length === 0) {
        setIsResponseEmpty(true);
      } else {
        setIsResponseEmpty(false);
      }

      const usageData = supabaseRes;

      setIsLoadingAPILogData(false);
      setCardData((prev) => ({
        ...prev,
        successRequests: usageData.successful_calls + usageData.empty_calls || 0,
        failedRequests: usageData.failed_calls,
        totalRequests:
          usageData.successful_calls +
          usageData.failed_calls +
          usageData.empty_calls || 0,
        emptyRequests: usageData.empty_calls,
      }));
      // setChartData((prev) => [...prev, ...data]);
    } catch (error) {
      setIsLoadingAPILogData(false);
    }
  }, [activeUser]);

  const loadAPIChartLogData = useCallback(async () => {
    try {
      setIsLoadingAPIChartLogData(false);
      let response: PostgrestResponse<{
        created_at: string;
        failed_calls_count: number;
        total_success_call_credits: number;
        successful_calls_count: number;
      }>;
      let chartDays = 0;
      const todayDate = new Date().toISOString();
      const daysThisMonth = new Date().getDate();
      let data = [];
      const filterParam = 30;
      chartDays = filterParam;
      // call super function get_user_call_stats
      // get one month ago date

      // response = await supabase.rpc(
      //   'customer_dashboard_api_stats_graph_rpc',
      //   {
      //     userid: user.id,
      //     start_date: subtractDays(todayDate, filterParam).toISOString(),
      //     end_date: todayDate,
      //   }
      // );

      response = await supabase
        .from('users_calls_and_credits_stats_prev_30days_from_now')
        .select('*')
        .eq('user_id', activeUser.id)
        .order('created_at', { ascending: true })
        .gte('created_at', subtractDays(todayDate, filterParam).toISOString())
        .lte('created_at', todayDate);

      data = constructAPILogChartData(
        response.data,
        subtractDays(
          new Date().toISOString(),
          filterParam as number
        ).toISOString(),
        chartDays
      );

      if (!response.data.length) {
        setIsResponseEmpty(true);
      } else {
        setIsResponseEmpty(false);
      }
      setIsLoadingAPIChartLogData(false);
      setChartData((prev) => [...prev, ...data]);
    } catch (error) {
      setIsLoadingAPIChartLogData(false);
    }
  }, [activeUser]);

  const handleDateRangeChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const days = e.target.value;
    setFilterDays(days as any);
    loadAPILogData(days as any);
    loadAPIChartLogData();
  };

  useEffect(() => {
    (async () => {
      loadAPILogData();
      loadAPIChartLogData();
    })();
  }, [activeUser]);
  return (
    <Card minH="auto" my={['1rem', '1.5rem', '2rem']} p={8}>
      <Flex
        mb={8}
        justify="space-between"
        gridGap={'20px'}
        direction={{ base: 'column', md: 'row' }}
        alignItems="center"
      >
        <Heading
          fontSize={['md', 'md', '2xl']}
          fontWeight="semibold"
          fontFamily="heading"
        >
          Requests Usage Statistics
        </Heading>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          gridGap="10px"
          alignItems={'center'}
        >
          <Select value={filterDays} onChange={handleDateRangeChange} w="200px">
            <option value="current_month">Current month</option>
          </Select>
        </Flex>
      </Flex>

      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(4,1fr)',
        }}
        gap={8}
      >
        <Flex
          position="relative"
          h="170px"
          rounded="6px"
          flexDir="column"
          alignItems="center"
          justify="center"
          bg="aliceblue"
        >
          {!isLoadingAPILogData && (
            <Text fontSize="3xl" fontWeight="bold" color="blue-crayola">
              {cardData.successRequests}
            </Text>
          )}
          {isLoadingAPILogData && <Spinner />}
          <Flex py="1rem" />
          <Flex pb="3rem" position="absolute" bottom={0}>
            <Text textAlign="center">Successful Requests</Text>
          </Flex>
        </Flex>
        <Flex
          position="relative"
          h="170px"
          rounded="6px"
          flexDir="column"
          alignItems="center"
          justify="center"
          bg="aliceblue"
        >
          {!isLoadingAPILogData && (
            <Text fontSize="3xl" fontWeight="bold" color="light-coral">
              {cardData.failedRequests + cardData.pendingRequests}
            </Text>
          )}
          {isLoadingAPILogData && <Spinner />}
          <Flex py="1rem" />
          <Flex pb="3rem" position="absolute" bottom={0}>
            <Text textAlign="center">Failed Requests</Text>
          </Flex>
        </Flex>
        {/* <Flex
          position="relative"
          h="170px"
          rounded="6px"
          flexDir="column"
          alignItems="center"
          justify="center"
          bg="aliceblue"
        >
          {!isLoadingAPILogData && (
            <Text fontSize="3xl" fontWeight="bold" color="#7B8287">
              {cardData.emptyRequests}
            </Text>
          )}
          {isLoadingAPILogData && <Spinner />}
          <Flex py="1rem" />
          <Flex pb="3rem" position="absolute" bottom={0} alignItems="center">
            <Text textAlign="center">Empty Requests</Text>{' '}
            <Link href="/empty-requests" isExternal>
              <ExternalLinkIcon
                paddingLeft={'2px'}
                color="#4E99FF"
                boxSize={5}
              />
            </Link>
          </Flex>
        </Flex> */}
        <Flex
          position="relative"
          h="170px"
          rounded="6px"
          flexDir="column"
          alignItems="center"
          justify="center"
          bg="aliceblue"
        >
          {!isLoadingAPILogData && (
            <Text fontSize="3xl" fontWeight="bold">
              {cardData.totalRequests}
            </Text>
          )}
          {isLoadingAPILogData && <Spinner />}
          <Flex py="1rem" />
          <Flex pb="3rem" position="absolute" bottom={0}>
            <Text textAlign="center">Total Requests</Text>
          </Flex>
        </Flex>
      </Grid>

      <Flex w="full" overflow="auto">
        <RequestChart
          isResponseEmpty={isResponseEmpty}
          xAxisLabel={filterDays as string}
          isLoading={isLoadingAPILogData}
          data={chartData}
        />
      </Flex>
    </Card>
  );
};

export default Usage;
