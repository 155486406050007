import { User } from '@supabase/supabase-js';
import { History, Location } from 'history';
import _ from 'lodash';
import cryptoJS from 'crypto-js';
import {
  SettingsIcon,
  DashboardIcon,
  ApiKeysIcon,
  BillingIcon,
} from '../components/Svgs/nav';
import { definitions } from '../supabaseClient';

export interface BaseProps {
  className?: string;
}

export interface RouterProps {
  history?: History;
  location?: Location;
  match?: any;
  staticContext?:
    | {
        statusCode?: number | undefined;
      }
    | undefined;
}

export interface BaseProps {
  className?: string;
}

export type Size = 'tiny' | 'regular' | 'large';
export type Alignment = 'left' | 'right';
export type BaseHtmlProps<T> = Omit<
  React.HTMLProps<T>,
  'ref' | 'size' | 'className'
>;

export interface SidenavLink {
  id?: number;
  to: string;
  isActive: boolean;
  text: string;
  icon: ({
    stroke,
    fill,
    ...rest
  }: React.SVGProps<SVGSVGElement>) => JSX.Element;
}

export interface Plan {
  id?: number;
  title: string;
  price: string;
  requests: string;
  btnText: string
}

export const GOD_MODE_USER_EMAILS = ["danishyasin33@gmail.com"];

export const navLinks: SidenavLink[] = [
  {
    id: 1,
    to: '/dashboard',
    isActive: false,
    text: 'Dashboard',
    icon: DashboardIcon,
  },
  {
    id: 2,
    to: '/dashboard/api-keys',
    isActive: false,
    text: 'API Keys',
    icon: ApiKeysIcon,
  },
  {
    id: 3,
    to: '/dashboard/settings',
    isActive: false,
    text: 'Settings',
    icon: SettingsIcon,
  },
  {
    id: 3,
    to: '/dashboard/billing',
    isActive: false,
    text: 'Billing',
    icon: BillingIcon,
  },
];

export const plans: Plan[] = [
  {
        id:1,
        title:"FREE Trial",
        price:"$0",
        requests:"100 FREE requests",
        btnText:"Get Free Trial"
  },
  {
        id:2,
        title:"Startup",
        price:"$79",
        requests:"10k requests",
        btnText:"Select Plan"
  },
  {
        id:3,
        title:"Growth",
        price:"$549",
        requests:"100k requests",
        btnText:"Select Plan"
  },
  {
        id:4,
        title:"Custom Plan",
        price:"$0",
        requests:"(For higher usage)",
        btnText:"Contact Us"
  },
];

export type Profile = User & {
  avatar?: string;
  password?: string;
  first_name?: string;
  credits?: number;
  last_name?: string;
  accepted_terms?: boolean;
  customer_id?: string;
};

export const subtractDays = (date: string, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() - (days - 1));
  return result;
};

export const addDays = (date: string, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getDaysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
};

export type ChartData = {
  day: string;
  FailedRequestsCount: number;
  SuccessfulRequestsCount: number;
  FailedColor: string;
  SuccessfulColor: string;
  Failed: number;
  Successful: number;
  date: string;
};

export type ActiveUser = {
  email: string;
  id: string;
}

export const constructAPILogChartData = (
  data: {
    created_at: string;
    failed_calls_count: number;
    total_success_call_credits: number;
    successful_calls_count: number;
  }[],
  startDate: string,
  chartDays: number
): ChartData[] => {
  const result = [];
  _.range(0, chartDays).forEach((num) => {
    const prevDate = addDays(startDate, num);
    const logs = data?.filter((d) => {
      return (
        new Date(d.created_at).toDateString() ===
        new Date(prevDate).toDateString()
      );
    });

    const chartData: ChartData = {
      day: `${num + 1}`,
      FailedRequestsCount: logs.length ? logs[0].failed_calls_count : 0,
      SuccessfulRequestsCount: logs.length ? logs[0].successful_calls_count : 0,
      FailedColor: '#FA6D76',
      SuccessfulColor: '#006CFF',
      Failed: 0,
      Successful: logs.length ? logs[0].total_success_call_credits : 0,
      date: logs.length
        ? new Date(logs[0].created_at).toLocaleDateString()
        : '',
    };

    result.push(chartData);
  });

  return result;
};

export const getHash = (userId: string) => {
  let time: any = new Date().toISOString();
  time = new Date(time);
  const timeInNum = parseInt(
    time.getUTCMonth().toString() +
      time.getUTCDate().toString() +
      time.getUTCHours().toString() +
      time.getUTCMinutes().toString()
  );

  const newKey = userId + Math.floor(timeInNum / 5);
  const newHash = cryptoJS.MD5(newKey).toString();
  return newHash;
};

export * from './database';
