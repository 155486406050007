import React from 'react';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/popover';
import MoreIcon from '../../../components/Svgs/common/MoreIcon';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';

interface Props {
  onEdit: () => void;
  onDelete: () => void;
}

const APIKeyMoreOptions = (props: Props) => {
  const { onEdit, onDelete } = props;

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Button p="0.5rem" h="2rem" bg="transparent">
          <MoreIcon />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        _focus={{ outline: 'none' }}
        rounded="none"
        border="none"
        shadow="0 3px 4px rgba(0, 0, 0, 0.15)"
        p={0}
        w="110px"
      >
        {/* <PopoverArrow /> */}
        <PopoverBody p={0}>
          <Flex
            disabled
            as="button"
            textAlign="left"
            w="100%"
            rounded="sm"
            fontSize="xs"
            fontWeight={500}
            cursor="pointer"
            alignItems="center"
            p="0.5rem"
            px="1rem"
            onClick={onEdit}
            _hover={{ background: 'gray.100' }}
          >
            Edit key
          </Flex>
          <Flex
            as="button"
            textAlign="left"
            w="100%"
            rounded="sm"
            fontSize="xs"
            fontWeight={500}
            cursor="pointer"
            alignItems="center"
            p="0.5rem"
            px="1rem"
            onClick={onDelete}
            _hover={{ background: 'gray.100' }}
          >
            Delete key
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default APIKeyMoreOptions;
