import Card from '../../../components/Card';
import { Flex, Heading, Link as CLink } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/icon';
import APIKeysTable, { ApiKeyType } from '../Apikey/APIKeysTable';
import { AddIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/button';
import React, { useEffect } from 'react';
import { definitions } from '../../../supabaseClient';
import { ActiveUser } from '../../../utils';

const APIKey = ({
  activeUser,
  onOpenCreateTokenModal,
  apiKeysList,
  setApiKeysList,
  onOpenEditTokenModal,
  onOpenConfirmDeleteAPIKey,
}: {
  activeUser: ActiveUser;
  onOpenCreateTokenModal: () => void;
  apiKeysList: ApiKeyType[];
  setApiKeysList: any;
  onOpenEditTokenModal: (apiKey: ApiKeyType) => void;
  onOpenConfirmDeleteAPIKey: (apiKey: ApiKeyType) => void;
}) => {
  return (
    <Card minH="auto" p={8}>
      <Flex
        mb={8}
        flexDir={{ base: 'column', md: 'row' }}
        justify="space-between"
        gridGap={'20px'}
        alignItems={['stretch', 'stretch', 'center']}
      >
        <Heading
          fontSize={['md', 'md', '2xl']}
          fontWeight="semibold"
          fontFamily="heading"
        >
          Your API Keys
        </Heading>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          gridGap={'20px'}
          justifyContent="center"
        >
          <CLink
            display="flex"
            alignItems="center"
            color="blue-crayola"
            fontSize={['md', 'md', 'md']}
            href="https://docs.supernovaapi.com"
            as="a"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            target="_blank"
          >
            API Documentation <Icon ml="0.2rem" as={ExternalLinkIcon} />
          </CLink>
          <Button
            borderWidth="1px"
            borderColor="light-coral"
            color="light-coral"
            display="none"
            // ml="1rem"
            fontSize={['sm', 'sm', 'md']}
            px={2}
            rounded="3px"
            bg="transparent"
            onClick={onOpenCreateTokenModal}
            _hover={{ bg: 'transparent' }}
          >
            <Icon mr="1rem" as={AddIcon} /> New API Key
          </Button>
        </Flex>
      </Flex>
      <APIKeysTable
        activeUser={activeUser}
        apiKeysList={apiKeysList}
        onEditAPIKey={onOpenEditTokenModal}
        setApiKeysList={setApiKeysList}
        onDeleteAPIKey={onOpenConfirmDeleteAPIKey}
      />
    </Card>
  );
};

export default APIKey;
