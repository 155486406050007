import { Button } from '@chakra-ui/button';
import { Flex, Heading, Text } from '@chakra-ui/layout';
import React from 'react';
import Modal from '../../../components/Modal/Modal';

interface Props {
  onClose: () => void;
  open: boolean;
  isDeletingApiKey: boolean;
  onConfirm: () => void;
}

const ConfirmDeleteKey = (props: Props) => {
  const { onClose, open, onConfirm, isDeletingApiKey } = props;

  return (
    <Modal p="8" maxW="500px" maxH="200px" open={open} onClose={onClose}>
      <Heading size="md" color="alert" mb="2" textAlign="center">
        Confirm delete API key
      </Heading>
      <Text as="p">
        Are you sure you want to delete this token? Once deleted, it is
        irriversible
      </Text>
      <Flex mt="1rem">
        <Button onClick={onClose} mr="1rem">
          Cancel
        </Button>
        <Button
          isLoading={isDeletingApiKey}
          onClick={onConfirm}
          bg="alert"
          _hover={{ bg: 'alert' }}
          color="white"
        >
          Delete
        </Button>
      </Flex>
    </Modal>
  );
};

export default ConfirmDeleteKey;
