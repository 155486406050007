import React, { useState } from 'react';
import Card from '../../components/Card';
import AccountSettingsLayout from './AccountSettingsLayout';
import { Flex, Heading, Text } from '@chakra-ui/layout';
import {
  FormControl,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/form-control';
import PasswordInput from '../../components/PasswordInput';
import { Button } from '@chakra-ui/button';
import { Message } from '../Auth/RegisterPage';
import { supabase } from '../../supabaseClient';
import { Auth } from '@supabase/auth-ui-react';
import SEO from '../../components/SEO';
import { baseUrl } from '../../context/Auth';
import withAuth from '../Auth/withAuth';

const initialState = {
  oldpassword: '',
  newpassword: '',
  confirmpassword: '',
};

const PasswordReset = () => {
  const [message, setMessage] = useState<Message>({ type: '', text: '' });
  const [isResetting, setIsResetting] = useState(false);
  const [state, setState] = useState(initialState);
  const { user } = Auth.useUser();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      setIsResetting(true);
      setMessage({ type: '', text: '' });
      if (!state.oldpassword) {
        setMessage({ type: 'error', text: 'Your old password is required' });
      } else {
        const authenticated = await supabase.auth.signInWithPassword({
          email: user?.email,
          password: state.oldpassword,
        });
        if (authenticated.error) {
          setMessage({ type: 'error', text: "You old password isn't correct" });
        } else if (state.newpassword !== state.confirmpassword) {
          setMessage({
            type: 'error',
            text: 'Confirm password does not match the new password',
          });
        } else {
          const res = await supabase.auth.updateUser({
            password: state.newpassword,
          });
          if (!res.error) {
            setMessage({
              type: 'success',
              text: 'Password updated successfully',
            });
          } else {
            setMessage({ type: 'error', text: res.error.message });
          }
        }
      }
      setIsResetting(false);
    } catch (error) {
      setIsResetting(false);
    }
  };

  return (
    <>
      <SEO
        title="Password reset"
        htmlAttributes={{ lang: 'en' }}
        link={[
          {
            rel: 'canonical',
            href: `${baseUrl}/password-reset`,
          },
        ]}
        meta={[
          {
            content: 'Account settings - Reset password',
            property: 'og:title',
          },
        ]}
      />
      <AccountSettingsLayout page="password_reset">
        <Card py="4rem" flexGrow={1}>
          <Heading textAlign="center" fontSize="3xl" mb="2rem" mx="auto">
            Reset Password
          </Heading>

          <Flex as="form" mx="auto" maxW="500px" flexDir="column">
            {message.type === 'error' && (
              <Flex
                mx="auto"
                maxW="500px"
                w="full"
                mb="0.5rem"
                borderWidth="1px"
                rounded="md"
                borderColor="alert"
                bg="red.50"
                p="1rem"
              >
                <Text fontSize="sm">{message.text}</Text>
              </Flex>
            )}
            {message.type === 'success' && (
              <Flex
                mx="auto"
                maxW="500px"
                w="full"
                mb="0.5rem"
                borderWidth="1px"
                rounded="md"
                borderColor="sea-green"
                bg="green.50"
                p="1rem"
              >
                <Text fontSize="sm">{message.text}</Text>
              </Flex>
            )}

            <Flex>
              <FormControl my="0.8rem" mx="0.5rem" id="oldpassword">
                <FormLabel fontWeight="normal">Enter old password</FormLabel>
                <PasswordInput
                  onChange={handleChange}
                  // value={state.oldpassword}
                  rounded="md"
                  id="oldpassword"
                  name="oldpassword"
                />
              </FormControl>
            </Flex>

            <Flex>
              <FormControl my="0.8rem" mx="0.5rem" id="newpassword">
                <FormLabel fontWeight="normal">Enter new password</FormLabel>
                <PasswordInput
                  onChange={handleChange}
                  // value={state.newpassword}
                  rounded="md"
                  id="newpassword"
                  name="newpassword"
                />
                <FormHelperText>
                  Your password requirements here.
                </FormHelperText>
              </FormControl>
            </Flex>

            <Flex>
              <FormControl my="0.8rem" mx="0.5rem" id="confirmpassword">
                <FormLabel fontWeight="normal">Confirm new password</FormLabel>
                <PasswordInput
                  onChange={handleChange}
                  // value={state.confirmpassword}
                  rounded="md"
                  id="confirmpassword"
                  name="confirmpassword"
                />
              </FormControl>
            </Flex>

            <Flex py="1rem" px="0.5rem">
              <Button
                py="1.5rem"
                type="submit"
                width="full"
                color="white"
                bg="orange-soda"
                _hover={{ bg: 'orange-soda' }}
                rounded="3px"
                onClick={handleSubmit}
                isLoading={isResetting}
              >
                Reset Password
              </Button>
            </Flex>
          </Flex>
        </Card>
      </AccountSettingsLayout>
    </>
  );
};

export default withAuth(PasswordReset);
