import { Flex } from '@chakra-ui/layout';
import styled from '@emotion/styled';

export const Backdrop = styled(Flex)`
  &.active {
    transition-duration: 50ms;
    transition-delay: 0ms;
    opacity: 1;

    & .modal-content {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 10ms;
      transition-duration: 100ms;
    }
  }
`;
