import React, { useState } from 'react';
import AuthLayout from './AuthLayout';
import Card from '../../components/Card';
import {
  Box,
  Flex,
  Heading,
  Link as CLink,
  Link,
  Text,
} from '@chakra-ui/layout';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Button } from '@chakra-ui/button';
import { Message } from './RegisterPage';
import { supabase } from '../../supabaseClient';
import SEO from '../../components/SEO';
import { baseUrl } from '../../context/Auth';

const ForgotPasswordPage = () => {
  const [message, setMessage] = useState<Message>({ type: '', text: '' });
  const [email, setEmail] = useState('');
  const [isSending, setIsSending] = useState(false);

  const onSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      if (!email) {
        setMessage({ type: 'error', text: 'Email address is required' });
      } else {
        const mailtoLink = `mailto:support@supernovaapi.com?subject=Password Reset Request&body=I would like to reset my password for the account associated with this email: ${email}`;
        window.location.href = mailtoLink;
      }
    } catch (_error) {
      setIsSending(false);
    }
  };

  return (
    <>
      <SEO
        title="Forgot password"
        htmlAttributes={{ lang: 'en' }}
        link={[
          {
            rel: 'canonical',
            href: `${baseUrl}/forgot-password`,
          },
        ]}
        meta={[
          {
            content: 'Forgot password',
            property: 'og:title',
          },
        ]}
      />
      <AuthLayout>
        <Card py="4rem" w="full" rounded="3px" h="600px">
          <Heading textAlign="center" size="lg">
            Forgot password
          </Heading>
          <Text textAlign="center" mb="3rem">
            Enter your email address and we will be in touch with you shortly.
          </Text>

          <Box as="form" maxW="550px" mx="auto">
            {message.type === 'error' && (
              <Flex
                w="full"
                mb="0.5rem"
                borderWidth="1px"
                rounded="md"
                borderColor="alert"
                bg="red.50"
                p="1rem"
              >
                <Text fontSize="sm">{message.text}</Text>
              </Flex>
            )}
            {message.type === 'success' && (
              <Flex
                w="full"
                mb="0.5rem"
                borderWidth="1px"
                rounded="md"
                borderColor="sea-green"
                bg="green.50"
                p="1rem"
              >
                <Text fontSize="sm">{message.text}</Text>
              </Flex>
            )}

            <Flex>
              <FormControl my="0.8rem" mx="0.5rem" id="email">
                <FormLabel>Email address</FormLabel>
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  rounded="md"
                  type="text"
                  id="email"
                  placeholder="Your email address"
                />
              </FormControl>
            </Flex>

            <Flex py="1rem" px="0.5rem">
              <Button
                py="1.5rem"
                type="submit"
                width="full"
                color="white"
                bg="orange-soda"
                _hover={{ bg: 'orange-soda' }}
                rounded="3px"
                onClick={onSubmit}
                isLoading={isSending}
              >
                Send email
              </Button>
            </Flex>

            <Flex px="0.5rem" justify="center" w="full">
              <CLink
                as={Link}
                href="/login"
                textAlign="center"
                color="orange-soda"
              >
                Go back to login
              </CLink>
            </Flex>
          </Box>
        </Card>
      </AuthLayout>
    </>
  );
};

export default ForgotPasswordPage;
