import { Box, Flex } from '@chakra-ui/layout';
import { Auth } from '@supabase/auth-ui-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderNavigation from '../../components/Navigation/HeaderNavigation';
import { supabase } from '../../supabaseClient';

interface Props {
  children: React.ReactNode;
  shouldRerender?: boolean;
}

const AuthLayout = ({ children, shouldRerender }: Props) => {
  const { user } = Auth.useUser();
  const history = useNavigate();

  useEffect(() => {
    (async () => {
      if (!!user) {
        try {
          const userStripeObj = await supabase
            .from('user_stripe')
            .select()
            .eq('id', user?.id);

          if (userStripeObj?.data[0]?.monthly_credits !== 0) {
            history('/dashboard');
          }
        } catch (error) {
          console.error(error)
        }
      }
    })()

  }, [user]);

  return (
    <Box bg="light-grey">
      <HeaderNavigation shouldRerender={shouldRerender} />
      <Flex w="full" py={['0', '0', '2rem']} px={['1rem', '1rem', '3.5rem']}>
        {children}
      </Flex>
    </Box>
  );
};

export default AuthLayout;
