import { PostgrestResponse } from '@supabase/postgrest-js';
import React, { useContext, useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { Profile } from '../utils';
import { SignInWithPasswordCredentials, Session, User } from '@supabase/supabase-js';

export const baseUrl =
  process.env.REACT_APP_BASE_URL || 'https://app.supernovaapi.com';

interface State {
  signUp: (data: Partial<Profile>) => Promise<{
    user: User;
    session: Session;
    error: Error;
    data: User | Session;
  }>;
  signIn: (data: Partial<User>) => Promise<{
    error: Error;
    data: {
      user: User,
      session: Session
    };
  }>;
  signOut: () => Promise<{ error: Error }>;
  user: Profile;
  subscriptionStatus: boolean;
  updateSubscriptionStatus: (status: boolean) => void;
}

const AuthContext = React.createContext({} as State);

export function AuthProvider({ children }) {
  const [user, setUser] = useState<Profile>(null);
  const [loading, setLoading] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState<boolean | null>(
    null
  );

  const updateSubscriptionStatus = (hasSubscription: boolean) => {
    setSubscriptionStatus(hasSubscription);
  };

  useEffect(() => {
    (async () => {
      // Check active sessions and sets the user
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setUser(session?.user ?? null);
      setLoading(false);

      const { data }: PostgrestResponse<Profile> = await supabase
        .from('user_profiles')
        .select('*')
        .match({ id: session?.user?.id });
      if (data) {
        setUser({ ...session?.user, ...data[0] });
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (user) {
        const { data, error } = await supabase
          .from('user_stripe')
          .select()
          .eq('id', user?.id);

        if (error) {
          console.error('Error fetching subscription status:', error);
          setSubscriptionStatus(false);
          return;
        }

        if (data && data[0]?.subscription_item_id != null) {
          setSubscriptionStatus(true);
        } else {
          setSubscriptionStatus(false);
        }
      }
    })();
  }, [user?.id]);

  // Will be passed down to Signup, Login and Dashboard components
  const value = {
    signUp: async (data: Partial<Profile>) => {
      const { data: userData, error } = await supabase.auth.signUp({
        email: data.email,
        password: data.password,
        options: {
          emailRedirectTo: `${baseUrl}/dashboard`,
        },
      });

      if (error) {
        console.error('Error during sign up:', error);
        return { error };
      }

      if (userData?.user) {
        const res = await supabase.from('user_profiles').insert({
          id: userData.user.id,
          first_name: data.first_name,
          email: data.email,
          last_name: data.last_name,
          credits: 100, // Free trial credits.
        });

        if (res.error) {
          return res;
        }
      }
      return userData;
    },
    signIn: (data: SignInWithPasswordCredentials) => supabase.auth.signInWithPassword(data),
    signOut: () => supabase.auth.signOut(),
    user,
    subscriptionStatus,
    updateSubscriptionStatus,
  };

  return (
    <AuthContext.Provider value={value as any}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
