import { CheckIcon } from '@chakra-ui/icons';
import { Box, Button, Text } from '@chakra-ui/react';

const Plan = ({ plan, stripeHandler, disableBtn }) => {
  const endpointsStyles = {
    borderBottom: '1px solid #ced7e5',
    minH: '53px',
    color: '#121e25',
    mb: '-1px',
    ml: '-1px',
    pt: '16px',
    pb: '16px',
    pl: '32px',
    fontSize: '18px',
  };

  const transition = {
    ':hover': {
      color: "white",
      bgColor: "#f7573e",
      textDecoration: "none"
    }
  };

  const wordSpacing = {
    wordSpacing: '0.5rem'
  }

  return (
    <Box
      w="94%"
      border="1px solid #ced7e5"
      borderRadius="4px"
      sx={
        plan.title === 'Growth'
          ? {
            border: "2px solid #F7573E",
            borderRadius: "4px",
            bgColor: "#abcffe4d",
          }
          : {}
      }
    >
      <Box minH="214px"
        textAlign="center"
        pt="32px"
        fontSize="24px"
        fontWeight="500"
        lineHeight="29px"
        borderBottom="1px solid #ced7e5">
        <Box fontWeight='700' color='#283442' font-family='Consolas,sans-serif' sx={wordSpacing}>{plan.title}</Box>
        <Box
          mt="12px"
          color="#475464"
          sx={
            plan.title === 'Custom Plan'
              ? {
                color: 'transparent',
              }
              : {}
          }
        >
          {plan.price}
        </Box>
        <Box fontSize="18px" fontWeight="400" mb="24px">{plan.requests}</Box>
        <Box><Button isDisabled={disableBtn} bgColor='rgb(250,251,254)' px="15px" py="9px" maxH="40px" fontSize="18px" fontFamily="Consolas, sans-serif" letterSpacing="0.06em" fontWeight="400" borderRadius="2px" color="#f7573e" border='1px solid #f7573e' sx={{
          ...transition, ...(plan.title === 'Growth' ? {
            color: "white",
            bgColor: "#f7573e"
          } : {})
        }} onClick={() => {
          stripeHandler(plan.id);
        }}>{plan.btnText}</Button>
        </Box>
      </Box>
      <Box sx={endpointsStyles}>
        <Text color="#086cfc" display="inline-block"><CheckIcon mr={2} /></Text>
        All Endpoints
      </Box>
      {/* <Box sx={endpointsStyles}>
        <Text color="#086cfc" display="inline-block"><CheckIcon mr={2} /></Text>
        TikTok Endpoints
      </Box>
      <Box borderBottomLeftRadius="4px" borderBottomRightRadius="4px" sx={endpointsStyles}>
        <Text color="#086cfc" display="inline-block"><CheckIcon mr={2} /></Text>
        Twitter Endpoints
      </Box> */}
      {plan.title === 'Custom Plan' && (
        <Box borderBottomLeftRadius="4px" sx={endpointsStyles}>
          <Text color="#086cfc" display="inline-block"><CheckIcon mr={2} /></Text>
          Custom development
        </Box>
      )}
    </Box>
  );
};

export default Plan;
