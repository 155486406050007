import React from 'react';

export const eyeOpen = (
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9114 6.11029C18.3362 6.64171 18.3362 7.35914 17.9114 7.88971C16.5733 9.56029 13.3631 13 9.61514 13C5.86717 13 2.65693 9.56029 1.31888 7.88971C1.11219 7.63526 1 7.32222 1 7C1 6.67778 1.11219 6.36474 1.31888 6.11029C2.65693 4.43971 5.86717 1 9.61514 1C13.3631 1 16.5733 4.43971 17.9114 6.11029V6.11029Z"
      stroke="#718096"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.6151 9.57143C11.1 9.57143 12.3037 8.42016 12.3037 7C12.3037 5.57984 11.1 4.42857 9.6151 4.42857C8.1302 4.42857 6.92645 5.57984 6.92645 7C6.92645 8.42016 8.1302 9.57143 9.6151 9.57143Z"
      stroke="#718096"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const eyeClose = (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.02032 12.4879C3.36682 11.3416 2.05835 9.85062 1.31898 8.90912C1.11223 8.64923 1 8.32952 1 8.00044C1 7.67136 1.11223 7.35165 1.31898 7.09175C2.65702 5.38637 5.86723 1.875 9.61517 1.875C11.2965 1.875 12.8684 2.58113 14.2127 3.51475"
      stroke="#718096"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.524 6.15112C11.2752 5.90408 10.9786 5.7076 10.6516 5.573C10.3245 5.4384 9.97344 5.36836 9.61852 5.36691C9.26361 5.36545 8.91191 5.43262 8.58372 5.56454C8.25553 5.69646 7.95734 5.89051 7.70634 6.13551C7.45535 6.38051 7.25652 6.6716 7.12133 6.99199C6.98614 7.31239 6.91726 7.65575 6.91866 8.00226C6.92006 8.34878 6.99172 8.69159 7.12951 9.01093C7.26729 9.33028 7.46847 9.61982 7.72144 9.86287"
      stroke="#718096"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.4455 15L16.7848 1"
      stroke="#718096"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.82275 13.866C8.40458 14.0351 9.00812 14.1223 9.61517 14.125C13.3631 14.125 16.5733 10.6136 17.9114 8.90825C18.118 8.64812 18.2302 8.32821 18.23 7.99898C18.2298 7.66974 18.1174 7.34994 17.9105 7.09C17.4402 6.49105 16.9357 5.91848 16.3995 5.375"
      stroke="#718096"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
