import { Flex } from '@chakra-ui/layout';
import React, { useCallback, useEffect, useState } from 'react';
import HeaderNavigation from '../Navigation/HeaderNavigation';
import { History } from 'history';
import { supabase } from '../../supabaseClient';
import { useToast } from '@chakra-ui/toast';
import { useAuth } from '../../context/Auth';

interface Props {
  children: React.ReactNode;
}

const DashboardLayout = (props: Props) => {
  const { children } = props;
  const { user } = useAuth();
  const toast = useToast();

  // const getAPILogs = useCallback(async (user) => {
  //   const res = await supabase.from('api_logs').select('credits_used');
  //   if (res.data.length !== 0) {
  //     const creditsUsed = res.data?.reduce((p, c, i, a) => {
  //       return p.credits_used + c.credits_used;
  //     });
  //     if (creditsUsed >= user.credits) {
  //       toast({
  //         title: 'Free credits used up',
  //         description:
  //           "You've exhausted all your free credits, subscribe to one of our premium plans for more credits",
  //         status: 'info',
  //         duration: 15000,
  //         isClosable: true,
  //         position: 'bottom-left',
  //       });
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!!user) {
  //     getAPILogs(user);
  //   }
  // }, [user]);

  return (
    <Flex direction="row" bg="light-grey">
      <Flex w="100%" h="100vh" overflowY="auto" flexDir="column">
        <HeaderNavigation />
        <Flex overflow="auto" flexDir="column" px={['1rem', '1rem', '3.5rem']}>
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DashboardLayout;
