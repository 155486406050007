import { Auth } from '@supabase/auth-ui-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { supabase } from './supabaseClient';
import 'react-circular-progressbar/dist/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/Auth';

const Main = () => {
  return (
    <React.StrictMode>
      <Auth.UserContextProvider supabaseClient={supabase}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Auth.UserContextProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
