import axios from 'axios';
import { getHash } from '../utils';
import { supabase } from '../supabaseClient';

export const useApiKeys = () => {
  // Get API KEYS
  const getApiKeys = async (userId) => {

    const response = await supabase
      .from('api_keys')
      .select('*')
      .match({ user_id: userId });

    return response.data;
  };

  return { getApiKeys };
};
