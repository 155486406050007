/* eslint-disable react/display-name */
/* eslint-disable react/react-in-jsx-scope */
import { Spinner, Flex } from '@chakra-ui/react';
import { Auth } from '@supabase/auth-ui-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent: any) => {
  return (props: any) => {
    const { user } = Auth.useUser();
    const history = useNavigate();

    useEffect(() => {
      if (!user) {
        history('/login');
      }
    }, [user]);

    return user ? (
      <WrappedComponent {...props} />
    ) : (
      <Flex w="full" justifyContent="center">
        <Spinner size="xl" />
      </Flex>
    );
  };
};

export default withAuth;
