import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import _ from 'lodash';
import APIKeyMoreOptions from './APIKeyMoreOptions';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { usePagination } from '@ajna/pagination';
import EmptyState from '../../../components/EmptyState';
import { definitions, supabase } from '../../../supabaseClient';
import Paginator from '../../../components/Paginator';
import { Skeleton } from '@chakra-ui/skeleton';
import { Icon } from '@chakra-ui/icon';
import { eyeClose, eyeOpen } from '../../../utils/svgs';
import { useApiKeys } from '../../../hooks/useApiKeys';
import { Auth } from '@supabase/auth-ui-react';
import { GOD_MODE_USER_EMAILS, ActiveUser } from '../../../utils';

export type ApiKeyType = {
  created_at: Date;
  customerId: string;
  id: string;
  name: string;
};

interface Props {
  activeUser: ActiveUser;
  onEditAPIKey: (apiKey: ApiKeyType) => void;
  setApiKeysList: React.Dispatch<React.SetStateAction<ApiKeyType[]>>;
  onDeleteAPIKey: (apiKey: ApiKeyType) => void;
  apiKeysList: ApiKeyType[];
}

// const initialPageSize = 25;

const APIKeysTable = (props: Props) => {
  const { getApiKeys } = useApiKeys();
  const { user, session } = Auth.useUser();
  const { onEditAPIKey, onDeleteAPIKey, activeUser } = props;
  const [apiKeysList, setApiKeysList] = useState([]);
  // const [totalKeys, setTotalKeys] = useState(0);
  const [keyVisibility, setKeyVisibility] = useState<string | undefined>(
    undefined
  );
  const [isLoadingAPIKeys, setIsLoadingAPIKeys] = useState(false);

  const isLoading = useMemo(() => isLoadingAPIKeys, [isLoadingAPIKeys]);

  /**
   * Declared and memoize functions that call the supabase API
   */

  const loadAPIKeys = useCallback(async (activeUser) => {
    try {
      setIsLoadingAPIKeys(true);
      if (GOD_MODE_USER_EMAILS.includes(user?.email)) {
        const accessToken = session.access_token;
        const response = await fetch(`https://u.supernova-493.workers.dev/api/keys?user_id=${activeUser.id}`,
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        const data = await response.json()
        const keys = data.api_key;
        setApiKeysList(keys);
      } else {
        const response = await getApiKeys(activeUser.id);
        setApiKeysList(response);
      }
      // setTotalKeys(response.keys.length);
      setIsLoadingAPIKeys(false);
      // const apiKeys = response.keys.map((key) => {
      //   return {
      //     ...key,
      //     customerId: key.user_id,
      //   };
      // });
      // setApiKeysList([...apiKeys]);
    } catch (error) {
      setIsLoadingAPIKeys(false);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    loadAPIKeys(activeUser);
  }, [activeUser]);

  // useEffect(() => {
  //   loadAPIKeys(pageSize, currentPage);
  // }, []);

  // useEffect(() => {
  //   setTotalKeys(apiKeysList?.length);
  // }, []);

  // handlers
  // const handlePageChange = (nextPage: number) => {
  //   console.log('page change', nextPage, currentPage);
  //   if (nextPage < currentPage) {
  //     console.log('previous clicked');
  //     console.log('previous data', previousData);
  //   } else {
  //     console.log('forward clicked');
  //   }
  //   setCurrentPage(nextPage);
  // };

  // const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
  //   const pageSize = Number(event.target.value);

  //   setPageSize(pageSize);
  // };

  const toggleKeyVisibility = (apiKey: ApiKeyType) =>
    setKeyVisibility((prev) => (prev === apiKey.id ? undefined : apiKey.id));

  return (
    <>
      <Box minH="250px" overflow="auto">
        <Table size="lg">
          <Thead border="none" h="3rem" bg="light-grey">
            <Tr border="none">
              <Th
                border="none"
                fontSize="lg"
                textTransform="capitalize"
                w="30%"
                py="0.5rem"
              >
                Name
              </Th>
              <Th
                border="none"
                fontSize="lg"
                textTransform="capitalize"
                w="40%"
                py="0.5rem"
              >
                Key
              </Th>
              <Th
                border="none"
                fontSize="lg"
                textTransform="capitalize"
                w="20%"
                py="0.5rem"
              >
                Date Created
              </Th>
              <Th w="5%" border="none" py="0.5rem"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading && !apiKeysList?.length && (
              <Tr>
                <Td colSpan={30} alignItems="center">
                  <Flex justify="center" align="center" w="100%" h="250px">
                    <EmptyState />
                  </Flex>
                </Td>
              </Tr>
            )}

            {
              isLoading && (
                // _.range(pageSize + 1).map((num) => (
                <React.Fragment>
                  <Tr>
                    <Td>
                      <Skeleton height="15px" w="full" />
                    </Td>
                    <Td>
                      <Skeleton height="15px" w="full" />
                    </Td>
                    <Td>
                      <Skeleton height="15px" w="full" />
                    </Td>
                    <Td>
                      <Skeleton height="15px" w="full" />
                    </Td>
                  </Tr>
                </React.Fragment>
              )
              // ))
            }
            {!isLoading &&
              apiKeysList
                ?.sort((a, b) =>
                  new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
                )
                ?.map((apiKey) => {
                  return (
                    <Tr key={`${apiKey.id}-${apiKey.user_id}`}>
                      <Td fontWeight="medium">
                        <Text>{apiKey.name}</Text>
                      </Td>
                      <Td fontWeight="medium">
                        <Flex alignItems="center" w="full">
                          <Text
                            minW="250px"
                            pl="0.3rem"
                            mr="0.8rem"
                            position="relative"
                          >
                            {keyVisibility !== apiKey.id && (
                              <Box
                                position="absolute"
                                backdropFilter="blur(3px)"
                                bg="rgba(135, 137, 139, 0.05)"
                                top={0}
                                bottom={0}
                                left={0}
                                w="full"
                              />
                            )}
                            {apiKey.id}
                          </Text>{' '}
                          <Flex
                            maxW="24px"
                            onClick={() => toggleKeyVisibility(apiKey)}
                            cursor="pointer"
                          >
                            {keyVisibility === apiKey.id ? (
                              <Icon
                                w="20px"
                                fill="gray.100"
                                as={() => eyeOpen}
                              />
                            ) : (
                              <Icon w="20px" as={() => eyeClose} />
                            )}
                          </Flex>
                        </Flex>
                      </Td>
                      <Td fontWeight="medium">
                        {new Date(apiKey.created_at).toDateString()}
                      </Td>
                      <Td fontWeight="medium" display="none">
                        <APIKeyMoreOptions
                          onDelete={() => onDeleteAPIKey(apiKey)}
                          onEdit={() => onEditAPIKey(apiKey)}
                        />
                      </Td>
                    </Tr>
                  );
                })}
          </Tbody>
        </Table>
      </Box>

      {/* {!!apiKeysList?.length && (
        <Paginator
          // total={30}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          isDisabled={isDisabled}
          pagesQuantity={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setIsDisabled={setIsDisabled}
          pageSize={pageSize}
          setPageSize={setPageSize}
          offset={offset}
        />
      )} */}
    </>
  );
};

export default APIKeysTable;
