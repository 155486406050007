import React from 'react';
import { useEffect, useState } from 'react';
import AuthLayout from './AuthLayout';
import Card from '../../components/Card';
import {
  Box,
  Flex,
  Heading,
  Link as CLink,
  Link,
  Text,
} from '@chakra-ui/layout';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Button } from '@chakra-ui/button';
import { Message } from './RegisterPage';
import PasswordInput from '../../components/PasswordInput';
import { supabase } from '../../supabaseClient';
import SEO from '../../components/SEO';
import { baseUrl } from '../../context/Auth';
import { useNavigate } from 'react-router-dom';

const ResetPasswordPage = () => {
  const [message, setMessage] = useState<Message>({ type: '', text: '' });
  const [passwords, setPasswords] = useState({
    newpassword: '',
    confirmPassword: '',
  });
  const [accessToken, setAccessToken] = useState('');
  const [shouldRerender, setShouldRerender] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      setShouldRerender(false);

      if (event === 'PASSWORD_RECOVERY') {
        window.localStorage.clear();
        setAccessToken(session.access_token);
      }
      if (event === 'USER_UPDATED') {
        // setShouldRerender(true);
        history('/dashboard');
      }
    });
  });

  const onSubmit = async () => {
    try {
      if (!passwords.newpassword) {
        setMessage({ type: 'error', text: 'New password is required' });
      } else if (!passwords.confirmPassword) {
        setMessage({ type: 'error', text: 'Confirm password is required' });
      } else {
        setIsResetting(true);
        const { data, error } = await supabase.auth.updateUser(
          {
            password: passwords.newpassword,
          }
        );

        if (data) {
          history('/dashboard');
        }

        if (error) {
          setIsResetting(false);
          setMessage({
            type: 'error',
            text: error.message || 'Something went wrong',
          });

          return;
        }

        setMessage({ type: 'success', text: 'Password reset successful' });
        setIsResetting(false);
      }
    } catch (error) {
      setIsResetting(false);
    }
  };

  return (
    <>
      <SEO
        title="Reset password"
        htmlAttributes={{ lang: 'en' }}
        link={[
          {
            rel: 'canonical',
            href: `${baseUrl}/reset-password`,
          },
        ]}
        meta={[{ content: 'Reset password', property: 'og:title' }]}
      />
      {/* <AuthLayout shouldRerender={shouldRerender}> */}
      <>
        <Card py="4rem" w="full" rounded="3px" h="600px">
          <Heading textAlign="center" size="lg">
            Reset password
          </Heading>
          <Text textAlign="center" mb="3rem">
            Reset your account's password
          </Text>

          <Box as="form" maxW="550px" mx="auto">
            {message.type === 'error' && (
              <Flex
                w="full"
                mb="0.5rem"
                borderWidth="1px"
                rounded="md"
                borderColor="alert"
                bg="red.50"
                p="1rem"
              >
                <Text fontSize="sm">{message.text}</Text>
              </Flex>
            )}
            {message.type === 'success' && (
              <Flex
                w="full"
                mb="0.5rem"
                borderWidth="1px"
                rounded="md"
                borderColor="sea-green"
                bg="green.50"
                p="1rem"
              >
                <Text fontSize="sm">{message.text}</Text>
              </Flex>
            )}

            <Flex>
              <FormControl my="0.8rem" mx="0.5rem" id="newpassword">
                <FormLabel>New Password</FormLabel>
                <PasswordInput
                  onChange={(e) => {
                    e.persist();
                    setPasswords((prev) => ({
                      ...prev,
                      newpassword: e.target.value,
                    }));
                  }}
                  value={passwords.newpassword}
                  rounded="md"
                  id="newpassword"
                  placeholder="Enter new password"
                />
              </FormControl>
            </Flex>

            <Flex>
              <FormControl my="0.8rem" mx="0.5rem" id="confirmpassword">
                <FormLabel>Confirm password</FormLabel>
                <PasswordInput
                  onChange={(e) => {
                    e.persist();
                    setPasswords((prev) => ({
                      ...prev,
                      confirmPassword: e.target.value,
                    }));
                  }}
                  value={passwords.confirmPassword}
                  rounded="md"
                  id="confirmpassword"
                  placeholder="Re-enter new password"
                />
              </FormControl>
            </Flex>

            <Flex py="1rem" px="0.5rem">
              <Button
                py="1.5rem"
                width="full"
                type="submit"
                color="white"
                bg="orange-soda"
                _hover={{ bg: 'orange-soda' }}
                rounded="3px"
                onClick={onSubmit}
                isLoading={isResetting}
              >
                Reset password
              </Button>
            </Flex>

            <Flex px="0.5rem" justify="center" w="full">
              <CLink
                as={Link}
                href="/login"
                textAlign="center"
                color="orange-soda"
              >
                Go back to login
              </CLink>
            </Flex>
          </Box>
        </Card>
      </>
      {/* </AuthLayout> */}
    </>
  );
};

export default ResetPasswordPage;
