import { Flex, Heading, Link as CLink, Text } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';
import { Auth } from '@supabase/auth-ui-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import HeaderNavigation from '../../components/Navigation/HeaderNavigation';
import { supabase } from '../../supabaseClient';
import withAuth from '../Auth/withAuth';
interface Props {
  children: React.ReactNode;
  page:
  | 'my_profile'
  | 'password_reset'
  | 'notifications'
  | 'overagebilling'
  | 'Get setup with Zapier and Supernova';
  isProfileUpdated?: boolean;
}

const AccountSettingsLayout = (props: Props) => {
  const [stripeLoading, setStripeLoading] = useState<{
    error: any;
    loading: boolean;
  }>({
    loading: false,
    error: undefined,
  });
  const { user, session } = Auth.useUser();
  const history = useNavigate();
  const [isBillingInfoActive, setIsBillingInfoActive] = useState(false);
  const toast = useToast();


  useEffect(() => {
    if (!user) {
      history('/login');
    }

  }, [history, user]);

  const renderNavigationLink = ({
    to,
    text,
    isActive,
    onClick,
    disabled,
  }: {
    to?: string;
    text: string | React.ReactNode;
    isActive: boolean;
    onClick?: () => void;
    disabled?: boolean;
  }) => {
    const activeStyles = isActive
      ? {
        borderLeftWidth: '0.3rem',
        bg: 'alice-blue',
        borderColor: 'true-blue',
        fontWeight: 'semibold',
      }
      : {};

    return (
      <CLink
        w="100%"
        display="inline-flex"
        borderLeftWidth="0.3rem"
        borderColor="transparent"
        onClick={onClick}
        opacity={disabled ? 0.9 : 1}
        alignItems="center"
        _hover={{
          textDecor: 'none',
          bg: disabled ? 'transparent' : 'alice-blue',
        }}
        _focusWithin={{ bg: 'alice-blue' }}
        _focus={{ outline: 'none' }}
        transition="color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.15s ease-in-out"
        position="relative"
        px="1rem"
        h="3.8rem"
        fontWeight="normal"
        to={to}
        as={to ? Link : undefined}
        {...activeStyles}
      >
        <Text>{text}</Text>
      </CLink>
    );
  };

  return (
    <Flex flexDir="column" bg="light-grey">
      <HeaderNavigation isProfileUpdated={props.isProfileUpdated} />

      <Flex
        maxW="1500px"
        mx="auto"
        w="full"
        flexDir="column"
        py={['2rem', '2rem', '4rem']}
        px={['1rem', '1rem', '4rem']}
      >
        <Heading fontSize={['1.8rem', '1.8rem', '2.5rem', '2.5rem']}>
          Account Settings
        </Heading>
        <Flex w="full" flexDir={['column', 'column', 'row']}>
          <Flex flexDir="column" justify="flex-start" mr={[0, 0, '2rem']}>
            <Card
              minH="100px"
              w={['full', 'full', '280px']}
              py={0}
              px={0}
              mb={0}
              rounded="sm"
            >
              {renderNavigationLink({
                text: 'My Profile',
                to: '/account-settings/profile',
                isActive: props.page === 'my_profile',
              })}
              {renderNavigationLink({
                text: 'Reset Password',
                to: '/account-settings/reset-password',
                isActive: props.page === 'password_reset',
              })}
              {renderNavigationLink({
                text: 'Overage Billing',
                to: '/account-settings/overagebilling',
                isActive: props.page === 'overagebilling',
              })}
              {renderNavigationLink({
                text: 'Email Notifications',
                to: '/account-settings/notifications',
                isActive: props.page === 'notifications',
              })}
              {/* {renderNavigationLink({
                text: 'Get setup with Zapier and Supernova',
                to: '/account-settings/no-code-solutions',
                isActive:
                  props.page === 'Get setup with Zapier and Supernova',
              })} */}
            </Card>
          </Flex>
          <Flex ml={[0, 0, '1rem']} flexGrow={1} position="relative">
            {stripeLoading.loading && (
              <Flex
                position="absolute"
                top="2.1rem"
                left={0}
                bottom="2.1rem"
                w="100%"
                bg="rgba(201, 197, 197, 0.1)"
                zIndex={2}
                cursor="no-drop"
                transition="all 20ms cubic-bezier(0.4, 0, 0.2, 1)"
              />
            )}
            {props.children}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default withAuth(AccountSettingsLayout);
