import { supabase } from '../supabaseClient';

export const setSubscriptionStatus = async (userId, updateSubscriptionStatus) => {
    const res = await supabase
      .from('user_stripe')
      .select()
      .eq('id', userId)
      .single();
    if (res?.data?.subscription_item_id != null) {
      updateSubscriptionStatus(true)
    } else {
      updateSubscriptionStatus(false);
    }
};
