// Check if email is fake and if fake add to superbase table fake_emails

import { definitions, supabase } from '../../supabaseClient';
import axios from 'axios';

// Api to check if email is valid or needs to be blocked
// const isValidEmail = async (email) => {
//   try {
//     // Currently failing to fetch due to api credits
//     const result = await fetch(
//       `https://verifymail.io/api/${email}?key=53ecbcf1ffb44eb39e1d97d79bec0ad7`,
//       {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           Host: 'verifymail.io',
//         },
//       }
//     );
//     console.log('result from api:', result);
//   } catch (e) {
//     console.log(e.message);
//   }
// };

export const isFakeEmail = async (email) => {
  try {
    const firstPart = email.split('@')[0];
    const emailDomain = email.split('@')[1];
    const lastThreeCharactersAreNumbers = /\d{3}$/.test(firstPart);

    // If the last three characters are numbers, it's a fake email
    if (lastThreeCharactersAreNumbers && emailDomain !== 'gmail.com') {
      // gmail.com is an exception from this rule, it will be caught by the next rule
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error in detecting email');
    return false;
  }
};

export const isPersonalEmail = async (email) => {
  try {
    const table = supabase.from('fake_domains');
    // Check if supabase already has this email's domain in fake_domains table
    const emailDomain = email.split('@')[1];
    const firstPart = email.split('@')[0];
    const exceptionList = [
      'brunocsncoelho@gmail.com',
      'justinpetersburg9@gmail.com',
      'casanova.geo1996@gmail.com',
      'thomas.cargee@gmail.com',
      'quannguyen2213@gmail.com',
      'weihao.2188@gmail.com',
      'oyesinaoyerinde@gmail.com',
      'kimjurs19@gmail.com',
      'emerald.ework@gmail.com',
      'francescosindacone@gmail.com'
    ];

    if (exceptionList.includes(email)) {
      return false;
    }
    const { data } = await table.select('*').eq('domain', emailDomain);
    // Email is fake

    if (data.length > 0) return true;
    return false;
  } catch (error) {
    console.error('Error in detecting email');
    return false;
  }
};
