import { Box, BoxProps } from '@chakra-ui/layout';
import React from 'react';

const Card = ({ children, ...rest }: BoxProps) => {
  return (
    <Box
      filter="drop-shadow(0px 0.5px 10px rgba(0, 0, 0, 0.05))"
      p="1rem"
      pb="2rem"
      pt="2rem"
      bg="white"
      my="2rem"
      minH="500px"
      borderRadius="10px"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Card;
