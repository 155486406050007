import React, { useCallback, useEffect, useState } from 'react';
import Card from '../../../components/Card';
import { Flex, Grid, Heading, Text, Box } from '@chakra-ui/layout';
import { Select, Spinner } from '@chakra-ui/react';
import { ActiveUser, subtractDays } from '../../../utils';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { supabase } from '../../../supabaseClient';
import { WarningIcon } from '@chakra-ui/icons';
import { Tooltip } from '@chakra-ui/react';

interface CreditProps {
  activeUser: ActiveUser;
  showDetails: boolean;
}

const Credit = ({ showDetails, activeUser }: CreditProps) => {
  const [isLoadingCredits, setIsLoadingCredits] = useState(false);
  const [isLoadingTotalCredits, setIsLoadingTotalCredits] = useState(false);
  const [creditCardData, setCreditCardData] = useState({
    monthlyCreditsUsed: 0,
    monthly_credits: 0,
    oneoffCreditsUsed: 0,
    unlimitedCreditUsed: 0,
    monthlyCreditsLeft: 0,
    oneoffCreditsLeft: 0,
    TotalCreditUsed: 0,
  });
  const loadCreditUsageData = useCallback(async () => {
    try {
      const todayDate = new Date().toISOString();
      const oneMonthAgoDate = subtractDays(todayDate, 30).toISOString();
      setIsLoadingCredits(true);
      setIsLoadingTotalCredits(true);

      const { data: supabaseRes, error } = await supabase
        .from('user_stripe')
        .select(
          'monthly_credits, monthly_credits_used, one_off_credits, unlimited_credits_used'
        )
        .eq('id', activeUser.id)
        .single();

      if (error) {
        throw error;
      }

      const usageData = supabaseRes;

      const processedData: any = {
        monthlyCreditsLeft: usageData
          ? usageData.monthly_credits - usageData.monthly_credits_used < 0
            ? 0
            : usageData.monthly_credits - usageData.monthly_credits_used
          : 0,
        oneoffCreditsLeft: usageData
          ? usageData.one_off_credits > 0
            ? usageData.one_off_credits
            : 0
          : 0,
        monthlyCreditsUsed: usageData ? usageData.monthly_credits_used : 0,
        unlimitedCreditUsed: usageData ? usageData.unlimited_credits_used : 0,
      };

      setCreditCardData((prev) => ({
        ...prev,
        oneoffCreditsLeft: processedData.oneoffCreditsLeft,
        monthlyCreditsUsed: processedData.monthlyCreditsUsed,
        monthly_credits: usageData ? usageData.monthly_credits : 0,
        monthlyCreditsLeft: processedData.monthlyCreditsLeft,
        unlimitedCreditUsed: processedData.unlimitedCreditUsed,
        TotalCreditUsed:
          processedData.monthlyCreditsUsed + processedData.unlimitedCreditUsed,
      }));

      setIsLoadingCredits(false);

      // const { data: oneOffCreditUsage, error: rpcErr } = await supabase.rpc(
      //   'get_oneoff_credits_used',
      //   {
      //     user_id: user.id,
      //     date: oneMonthAgoDate,
      //   }
      // );

      // if (rpcErr) {
      //   throw rpcErr;
      // }

      // Object.assign(processedData, {
      //   oneOffCreditUsed:
      //     oneOffCreditUsage.length > 0
      //       ? oneOffCreditUsage[0].total_oneoff_credits_used
      //       : 0,
      // });

      // setCreditCardData((prev) => ({
      //   ...prev,
      //   TotalCreditUsed:
      //     processedData.monthlyCreditsUsed +
      //     processedData.unlimitedCreditUsed +
      //     processedData.oneOffCreditUsed,

      //   oneoffCreditsUsed: processedData.oneOffCreditUsed,
      // }));

      // setIsLoadingTotalCredits(false);
    } catch (error) {
      console.log(error);
      console.log('error in loading credit usage data');
      setIsLoadingCredits(false);
      setIsLoadingTotalCredits(false);
    }
  }, [activeUser]);
  useEffect(() => {
    (async () => {
      loadCreditUsageData();
    })();
  }, [activeUser]);

  return (
    <Card minH="auto" my={['1rem', '1.5rem', '2rem']} p={8}>
      <Flex mb={8} justify="space-between" alignItems="center">
        <Heading
          fontSize={['md', 'md', '2xl']}
          fontWeight="semibold"
          fontFamily="heading"
        >
          Credit Usage Statistics
        </Heading>
        <Select value={30} onChange={() => { }} w="200px">
          <option value="30">Last 30 days</option>
        </Select>
      </Flex>

      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
        gap={8}
      >
        {/* Progress Stats */}
        <Flex
          position="relative"
          // h="170px"
          py={'30px'}
          rounded="6px"
          flexDir="column"
          alignItems="center"
          justify="center"
          bg="aliceblue"
        >
          {!isLoadingCredits && (
            <Tooltip
              hasArrow
              width={150}
              placement="right"
              bg={'white'}
              gutter={15}
              boxShadow="xl"
              label={
                <Box py="5px" px="5px" borderRadius={'10px'}>
                  <Flex alignItems={'center'} gridGap={2} mb="5px">
                    <Box width={'10px'} height="10px" bg="#006CFF"></Box>
                    <Flex color={'#283442 '}>
                      Left:{' '}
                      <Text color={'#006CFF '} ml="6px">
                        {creditCardData.monthlyCreditsLeft}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex alignItems={'center'} gridGap={2}>
                    <Box width={'10px'} height="10px" bg="#CED7E5 "></Box>
                    <Flex color={'#283442 '}>
                      Used:{' '}
                      <Text color={'#CED7E5'} ml="6px">
                        {creditCardData.monthlyCreditsUsed >
                          creditCardData.monthly_credits
                          ? creditCardData.monthly_credits
                          : creditCardData.monthlyCreditsUsed}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              }
            >
              <Box width={200} cursor="pointer">
                <CircularProgressbarWithChildren
                  // Remaining credits => 265
                  value={
                    (creditCardData.monthlyCreditsLeft /
                      creditCardData.monthly_credits) *
                    100
                  }
                  counterClockwise
                  styles={{
                    trail: {
                      strokeLinecap: 'butt',
                      stroke: '#CED7E5 ',
                    },
                    path: {
                      strokeLinecap: 'butt',
                      stroke: '#006CFF',
                    },
                  }}
                >
                  <Text textAlign={'center'} color="#283442 ">
                    <Text fontSize={'28px'} color="#006CFF">
                      {creditCardData.monthlyCreditsLeft}
                    </Text>
                    of <strong>{creditCardData.monthly_credits}</strong> Left
                  </Text>
                </CircularProgressbarWithChildren>
              </Box>
            </Tooltip>
          )}
          {isLoadingCredits && <Spinner />}
          <Flex py="1rem" />
          <Flex bottom={0}>
            <Text textAlign="center">Monthly Credits</Text>
          </Flex>
        </Flex>

        <Flex
          position="relative"
          // h="170px"
          h={{ base: '170', md: '100%' }}
          rounded="6px"
          flexDir="column"
          alignItems="center"
          justify="center"
          bg="aliceblue"
        >
          {!isLoadingCredits && (
            <Text fontSize="3xl" fontWeight="bold">
              {creditCardData?.oneoffCreditsLeft || 0}
            </Text>
          )}
          {isLoadingCredits && <Spinner />}
          <Flex py="1rem" />
          <Flex pb="3rem" alignItems={'center'} position="absolute" bottom={0}>
            <Text>One Off Credits left</Text>
          </Flex>
        </Flex>

        {/* Progress Stats */}

        {/* Number Stats */}
        <Flex flexDirection={'column'} gridGap={8}>
          <Flex
            position="relative"
            h="170px"
            rounded="6px"
            flexDir="column"
            alignItems="center"
            justify="center"
            bg="aliceblue"
          >
            {!isLoadingCredits && (
              <Text fontSize="3xl" fontWeight="bold">
                {creditCardData?.unlimitedCreditUsed || 0}
              </Text>
            )}
            {isLoadingCredits && <Spinner />}
            <Flex py="1rem" />
            <Flex
              pb="3rem"
              alignItems={'center'}
              position="absolute"
              bottom={0}
            >
              <Text>Unlimited Credits Used</Text>
              <Tooltip label="Unlimited credits used" fontSize="md">
                <WarningIcon ml={'10px'} />
              </Tooltip>
            </Flex>
          </Flex>
          <Flex
            position="relative"
            h="170px"
            rounded="6px"
            flexDir="column"
            alignItems="center"
            justify="center"
            bg="aliceblue"
          >
            {!isLoadingCredits && (
              <Text fontSize="3xl" fontWeight="bold">
                {creditCardData?.TotalCreditUsed || 0}
              </Text>
            )}
            {isLoadingCredits && <Spinner />}
            <Flex py="1rem" />
            <Flex pb="3rem" position="absolute" bottom={0}>
              <Text textAlign="center">Total Credits Used</Text>
            </Flex>
            <Text textAlign="center" fontSize="xs" mt={'1.5rem'}>
              <WarningIcon ml={'10px'} /> This calculation does not include "One
              Off Credits"
            </Text>
          </Flex>
        </Flex>
        {/* Number Stats */}
      </Grid>
    </Card>
  );
};

export default Credit;
