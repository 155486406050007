import * as React from 'react';

function MoreIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.667 8.667a.667.667 0 100-1.334.667.667 0 000 1.334zM8 8.667a.667.667 0 100-1.334.667.667 0 000 1.334zM13.333 8.667a.667.667 0 100-1.334.667.667 0 000 1.334z"
        stroke="#444"
        strokeWidth={2}
      />
    </svg>
  );
}

export default MoreIcon;
