import {
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  useBreakpointValue
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { useEffect, useState } from 'react';
import HeaderNavigation from '../../components/Navigation/HeaderNavigation';
import { supabase } from '../../supabaseClient';
import { Profile, plans } from '../../utils';
import Plan from './Plan';
import PlanLarge from './PlanLarge';

const PricingPage = () => {
  const [user, setUser] = useState<Profile>(null);
  const [disableBtns, setDisableBtns] = useState([false, false, false]);
  const toast = useToast();
  useEffect(() => {
    (async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        if (error) throw error;
        setUser(session?.user ?? null);
      } catch (error) {
        toast({
          title: error.message || 'Something went wrong',
          position: 'top',
          isClosable: true,
          status: 'error',
        });
      }
    })();
  }, []);

  const endpointsStyles = {
    borderBottom: '1px solid #ced7e5',
    minH: '53px',
    color: '#121e25',
    mb: '-1px',
    ml: '-1px',
    pt: '16px',
    pb: '16px',
    pl: '32px',
    fontSize: '18px',
  };

  const wordSpacing = {
    wordSpacing: '0.5rem',
  };

  const h1FontSizes = ['5vw', '4.1vw', '32px'];

  const h1FontSize = useBreakpointValue({
    base: h1FontSizes[2],
    sm: h1FontSizes[2],
    md: h1FontSizes[0],
    lg: h1FontSizes[1],
  });

  const isLargeScreen = useBreakpointValue({ base: false, lg: true });

  const stripeHandler = async (planId) => {
    try {
      setDisableBtns((prev) => prev.map((_, index) => index + 1 === planId));
      const email = encodeURIComponent(user.email);
      const response = await fetch(
        `https://s.supernova-493.workers.dev/webhook/checkout?itemsId=${planId}&email=${email}&userId=${user.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain',
          }
        }
      );
      if (response.ok) {
        const data = await response.json();
        const url = data.url;

        window.location.href = url;
      } else {
        const body = await response.json();
        console.error(body.error);

        toast({
          title: 'Checkout Error',
          description: body.error || 'Something went wrong during checkout',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      setTimeout(() => {
        setDisableBtns((prev) => prev.map(() => false));
      }, 2000);
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        setDisableBtns((prev) => prev.map(() => false));
      }, 2000);
      toast({
        title: 'An error occurred',
        description: 'Something went wrong during checkout',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <HeaderNavigation />
      <Box as="section" mt="50px">
        <VStack
          spacing="30px"
          textAlign="center"
          mx="auto"
          maxW="417px"
          mb="30px"
        >
          <Heading as="h1" fontSize={h1FontSize}>
            Pricing
          </Heading>
          <Text fontSize="18px" lineHeight="1.45em">
            Compare plans & choose what suits you best depending on your
            expected volumes
          </Text>
        </VStack>

        {isLargeScreen ? (
          <Box w="97%" maxW="1240px" mx="auto" borderRadius="4px">
            <Flex w="100%">
              <Flex
                borderTopLeftRadius="4px"
                flexDir="column"
                w="100%"
                maxW="337px"
                border="1px solid #ced7e5"
                borderBottomLeftRadius="4px"
                flex="2"
              >
                <Box
                  minH="214px"
                  textAlign="center"
                  pt="32px"
                  fontSize="24px"
                  fontWeight="700"
                  lineHeight="29px"
                  borderBottom="1px solid #ced7e5"
                  bgColor="#e7e7e737"
                  sx={wordSpacing}
                >
                  Select a monthly plan
                </Box>
                <Box sx={endpointsStyles}>All Endpoints</Box>
                <Box borderBottomLeftRadius="4px" sx={endpointsStyles}>
                  Hands on Development
                </Box>
              </Flex>
              {plans.map((plan) => {
                return (
                  <PlanLarge
                    key={plan.id}
                    plan={plan}
                    stripeHandler={stripeHandler}
                    disableBtn={disableBtns[plan.id - 1]}
                  />
                );
              })}
            </Flex>
          </Box>
        ) : (
          <VStack spacing="30px">
            {plans.map((plan) => {
              return (
                <Plan
                  key={plan.id}
                  plan={plan}
                  stripeHandler={stripeHandler}
                  disableBtn={disableBtns[plan.id - 1]}
                />
              );
            })}
          </VStack>
        )}
      </Box>
    </>
  );
};

export default PricingPage;
