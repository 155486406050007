import React, { useMemo } from 'react';
import { Button } from '@chakra-ui/button';
import { Input } from '@chakra-ui/input';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import Modal from '../../../components/Modal/Modal';
import { definitions } from '../../../supabaseClient';
import { ApiKeyType } from './APIKeysTable';

interface Props {
  onClose: () => void;
  open: boolean;
  keyAction: '' | 'create' | 'edit';
  currentApiKey: ApiKeyType;
  setCurrentApiKey: React.Dispatch<React.SetStateAction<ApiKeyType>>;
  onConfirm: () => void;
  isSavingApiKey: boolean;
}

const CreateOrEditAPIKey = (props: Props) => {
  const {
    open,
    onClose,
    keyAction,
    currentApiKey,
    isSavingApiKey,
    onConfirm,
    setCurrentApiKey,
  } = props;
  const apiKey = useMemo(() => currentApiKey, [currentApiKey]);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const { name, value } = e.target;
    setCurrentApiKey((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      p="8"
      maxW="500px"
      mx="1rem"
      maxH="370px"
      position="relative"
      open={open}
      onClose={onClose}
    >
      <Box
        as="button"
        onClick={onClose}
        position="absolute"
        top="0.5rem"
        right="1rem"
        fontSize="2xl"
      >
        &times;
      </Box>
      <Heading mb="1rem" size="md" textAlign="left">
        {keyAction === 'create' && 'Create New API Key'}
        {keyAction === 'edit' && 'Update API key'}
      </Heading>
      <Text>
        You can create multiple keys to serve different applications or use
        cases and name them to make it easy to track the usage.
      </Text>

      <Box my="6">
        <Text as="label" fontWeight="medium" htmlFor="name">
          API Key Name
        </Text>
        <Input
          onChange={onNameChange}
          value={apiKey?.name || ''}
          id="name"
          name="name"
          my="0.5rem"
          placeholder="e.g. New Followers API"
          autoFocus
        />
      </Box>
      <Flex>
        <Button
          w="full"
          bg="orange-soda"
          color="white"
          _hover={{ bg: 'orange-soda' }}
          onClick={onConfirm}
          isLoading={isSavingApiKey}
        >
          {keyAction === 'create' && 'Create API Key'}
          {keyAction === 'edit' && 'Save API Key'}
        </Button>
      </Flex>
    </Modal>
  );
};

export default CreateOrEditAPIKey;
