/* eslint react/no-find-dom-node: 0 */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import classNames from 'classnames';

export interface OutsideClickProps {
  onOutsideClick: (event: Event) => void;
  children: React.ReactElement<any>;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
}

export const OutsideClick = React.forwardRef<HTMLDivElement, OutsideClickProps>(
  (props, ref) => {
    const { children, className, onOutsideClick, ...rest } = props;

    const innerRef = React.useRef<HTMLDivElement>(null);

    React.useImperativeHandle(ref, () => innerRef.current!, [innerRef]);

    const handleOutsideClick = React.useCallback(
      (event: Event) => {
        const element = innerRef;

        if (!event.target || !element.current) {
          return;
        }

        if (
          !ReactDOM.findDOMNode(element.current)!.contains(
            event.target as HTMLElement
          )
        ) {
          onOutsideClick(event);
        }
      },
      [onOutsideClick]
    );

    React.useEffect(() => {
      document.addEventListener('click', handleOutsideClick, true);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, [handleOutsideClick]);

    const classes = classNames(className);

    return (
      <span ref={innerRef} {...rest} className={classes}>
        {children}
      </span>
    );
  }
);

export default OutsideClick;
