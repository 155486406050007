import { extendTheme, theme as chakraTheme } from '@chakra-ui/react';
import { Colors as ChakraColors } from '@chakra-ui/theme';
import {
  createBreakpoints,
  Breakpoints as ChakraBreakpoints,
} from '@chakra-ui/theme-tools';

type Breakpoints = ChakraBreakpoints<{
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  '2xl': string;
}>;

type Colors = ChakraColors & {
  black: string;
  white: string;
  bluetiful: string;
  'light-grey': string;
  primary: string;
  'primary-light': string;
  'dark-blue': string;
  'true-blue': string;
  'dark-blue-light': string;
  'old-rose': string;
  'old-rose-light': string;
  alert: string;
  'pale-pink': string;
  'blue-crayola': string;
  'sea-green': string;
  'alice-blue': string;
  'light-coral': string;
  'orange-soda': string;
};

type Fonts = 'heading' | 'body';

const breakpoints: Breakpoints = createBreakpoints({
  xs: '321px',
  sm: '415px',
  md: '769px',
  lg: '1025px',
  xl: '1281px',
  '2xl': '1537px',
});

const colors: Colors = {
  ...chakraTheme.colors,
  black: '#333333',
  white: '#ffffff',
  'light-grey': '#fafbfe',
  bluetiful: '#0669F8',
  primary: '#0099FE',
  'primary-light': '#38A7F0',
  'dark-blue': '#0D285F',
  'true-blue': '#3A6CB9',
  'dark-blue-light': '#314977',
  'old-rose': '#BD7D7D',
  'old-rose-light': '#CB9C9C',
  'light-coral': '#FA6D76',
  'sea-green': '#49B27D',
  alert: '#FE0C0C',
  subtle: '#868686',
  'pale-pink': '#FBD5CF',
  'alice-blue': '#EBF3FF',
  'blue-crayola': '#006CFF',
  'orange-soda': '#F7573E',
};

const fonts: Record<Fonts, string> = {
  heading: "'Consolas', monospace",
  body: "'Inter', sans-serif",
};

export const theme = extendTheme({
  ...chakraTheme,
  colors,
  breakpoints,
  fonts,
});
