import { ChevronDownIcon } from '@chakra-ui/icons';
import { Link as CLink, Flex, Text } from '@chakra-ui/layout';
import {
  Avatar,
  Button,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  SkeletonCircle,
} from '@chakra-ui/react';
import { Auth } from '@supabase/auth-ui-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/Auth';
import { supabase } from '../../supabaseClient';
import { Profile } from '../../utils';
import { OutsideClick } from '../OutsideClick';

interface Props {
  shouldRerender?: boolean;
  isProfileUpdated?: boolean;
}

const UserAvatar = ({
  user,
  isLoadingUser,
}: {
  user: Profile;
  isLoadingUser;
}) => {
  const history = useNavigate();
  const [openPopover, setOpenPopover] = useState(false);

  const handleLogOut = async () => {
    await supabase.auth.signOut();
    history('/login');
  };

  const handlePageLink = (link: string) => {
    history(link);
  };

  return (
    <OutsideClick onOutsideClick={() => setOpenPopover(false)}>
      <Popover isOpen={openPopover} placement="bottom-end">
        <PopoverTrigger>
          <Flex
            onClick={() => setOpenPopover((prev) => !prev)}
            alignItems="center"
          >
            <Flex
              _hover={{ bg: 'pale-pink', transition: 'all 0.5s linear' }}
              justify="center"
              alignItems="center"
              p="0.15rem"
              rounded="full"
              cursor="pointer"
            >
              {isLoadingUser ? (
                <SkeletonCircle size="9" />
              ) : (
                <Avatar
                  height="2.2rem"
                  w="2.2rem"
                  rounded="full"
                  bg="white"
                  src={user?.avatar ?? '/default-avatar.svg'}
                />
              )}
            </Flex>
            <ChevronDownIcon cursor="pointer" w="20px" h="20px" />
          </Flex>
        </PopoverTrigger>
        <PopoverContent
          _focus={{ outline: 'none' }}
          zIndex={10}
          mt="0.5rem"
          shadow="0 1px 6px rgba(0, 0, 0, 0.1)"
          w="200px"
        >
          <PopoverHeader
            px={4}
            py={3}
            _hover={{ cursor: 'pointer' }}
            borderBottom="none"
            bg="alice-blue"
            color="blue-crayola"
          >
            Hi, {user?.first_name}
          </PopoverHeader>
          <PopoverBody p={0}>
            <Flex
              onClick={() => handlePageLink('/dashboard')}
              _hover={{ cursor: 'pointer', bg: 'gray.100' }}
              px={4}
              py={3}
            >
              <Text>Dashboard</Text>
            </Flex>
            <Flex
              onClick={() => handlePageLink('/account-settings/profile')}
              _hover={{ cursor: 'pointer', bg: 'gray.100' }}
              px={4}
              py={3}
            >
              <Text>Account Settings</Text>
            </Flex>
            {/* <Flex
              onClick={() => window.open('https://status.supernovaapi.com/')}
              _hover={{ cursor: 'pointer', bg: 'gray.100' }}
              px={4}
              py={3}
            >
              <Center>
                <Text>Status</Text>
                <ExternalLinkIcon ml={2} />
              </Center>
            </Flex> */}
          </PopoverBody>
          <PopoverFooter
            onClick={handleLogOut}
            _hover={{ cursor: 'pointer', bg: 'gray.100' }}
            px={4}
            py={3}
          >
            Log Out
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </OutsideClick>
  );
};

const HeaderNavigation = (props: Props) => {
  const { user, session } = Auth.useUser();
  const { subscriptionStatus } = useAuth();
  const [isBillingInfoActive, setIsBillingInfoActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stripeLoading, setStripeLoading] = useState({
    loading: false,
    error: undefined,
  });
  const [profile, setprofile] = useState({} as Profile);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const history = useNavigate();

  const handlePageLink = (link: string) => {
    history(link);
  };

  const downloadImage = async (path: string) => {
    if (path && path !== 'undefined') {
      const res = await supabase.storage
        .from('avatars')
        .download(path?.replace('avatars/', ''));
      return res;
    }
    return { data: null, error: null };
  };

  const fetchProfile = useCallback(async () => {
    setIsLoadingUser(true);
    const response = await supabase
      .from('user_profiles')
      .select('*')
      .match({ id: user?.id });
    let url: {
      data: Blob;
      error: Error;
    } = null;
    if (!response.error) {
      url = await downloadImage(response.data[0].avatar);
      setprofile((prev) => ({
        ...prev,
        ...response.data[0],
        avatar:
          url && url.data
            ? URL.createObjectURL(url.data)
            : '/default-avatar.svg',
      }));
    }
    setIsLoadingUser(false);
  }, []);

  const handleStripeApiRequest = async () => {
    setIsBillingInfoActive(true);
    setStripeLoading({ loading: true, error: undefined });
    try {
      const accessToken = session.access_token;
      const result = await fetch(
        `https://s.supernova-493.workers.dev/webhook/portal?pathname=${location.pathname}`,
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      setStripeLoading({ loading: false, error: '' });
      const { redirectUri } = await result.json();
      if (redirectUri) {
        window.location.href = redirectUri;
      }
    } catch (error) {
      setStripeLoading({ loading: false, error });
    }
  };

  useEffect(() => {
    if (user) {
      fetchProfile();
    } else {
      setprofile({} as Profile);
    }
  }, [props.shouldRerender, props.isProfileUpdated]);

  return (
    <Flex
      px={['1rem', '1rem', '3.5rem']}
      justifyContent="space-between"
      bg="white"
      alignItems="center"
      h="5rem"
      minH="5rem"
      w="100%"
    >
      <CLink as={Link} to="/dashboard">
        <Image
          src="https://supernova-webapp-assets.s3.amazonaws.com/sn-cropped.png"
          w="160px"
        />
      </CLink>

      {user && (
        <Flex align="center">
          {subscriptionStatus && (
            <Button
              borderWidth="1px"
              borderColor="light-coral"
              color="light-coral"
              mr="1rem"
              h="2rem"
              lineHeight={1.8}
              rounded="3px"
              fontFamily="heading"
              bg="transparent"
              _hover={{ bg: 'light-coral', color: 'white' }}
              onClick={handleStripeApiRequest}
              isLoading={stripeLoading.loading}
              disabled={isBillingInfoActive}
            >
              Manage Subscription
            </Button>
          )}
          {subscriptionStatus === false && location.pathname !== '/pricing' && (
            <Button
              borderWidth="1px"
              borderColor="light-coral"
              color="light-coral"
              mr="1rem"
              h="2rem"
              lineHeight={1.8}
              rounded="3px"
              fontFamily="heading"
              bg="transparent"
              _hover={{ bg: 'light-coral', color: 'white' }}
              onClick={() => handlePageLink('/pricing')}
            >
              Upgrade
            </Button>
          )}
          <UserAvatar isLoadingUser={isLoadingUser} user={profile} />
        </Flex>
      )}

      {!user && (
        <Flex align="center">
          <CLink
            to="/login"
            as={Link}
            fontFamily="heading"
            _hover={{ textDecoration: 'none' }}
            px="1rem"
          >
            Login
          </CLink>
          {/* <Button
            borderWidth="1px"
            borderColor="light-coral"
            color="light-coral"
            ml="1rem"
            h="2.5rem"
            rounded="3px"
            fontFamily="heading"
            bg="transparent"
            _hover={{ bg: 'light-coral', color: 'white' }}
            onClick={() => history.push('/register')}
          >
            Register
          </Button> */}
        </Flex>
      )}
    </Flex>
  );
};

export default HeaderNavigation;
