import { Flex, FlexProps, Text } from '@chakra-ui/layout';
import React from 'react';
import ExclamationIcon from '../Svgs/common/ExclamationIcon';

interface Props extends FlexProps {
  text?: string;
}

const EmptyState = ({ text, ...rest }: Props) => {
  return (
    <Flex flexDir="column" align="center" justify="center" {...rest}>
      <ExclamationIcon />
      <Text mt="1rem" color="gray.600" textAlign="center">
        {text || 'No data available'}
      </Text>
    </Flex>
  );
};

export default EmptyState;
