import DashboardLayout from '../../components/DashboardLayout';
import { getHash } from '../../utils';
import { Flex, Grid, Text, Box, Heading } from '@chakra-ui/layout';
import axios from 'axios';
import Select from "react-select";
import React, { useCallback, useState, useEffect } from 'react';
import CreateOrEditAPIKey from './Apikey/CreateOrEditAPIKey';
import { definitions, supabase } from '../../supabaseClient';
import { useToast } from '@chakra-ui/toast';
import { Auth } from '@supabase/auth-ui-react';
import { PostgrestResponse } from '@supabase/postgrest-js';
import ConfirmDeleteKey from './Apikey/ConfirmDeleteKey';
import SEO from '../../components/SEO';
import { baseUrl } from '../../context/Auth';
import APIKey from './Apikey';
import Usage from './Usage';
import Credit from './Credit';
import { useApiKeys } from '../../hooks/useApiKeys';
import { ApiKeyType } from './Apikey/APIKeysTable';
import withAuth from '../../features/Auth/withAuth';
import { GOD_MODE_USER_EMAILS, ActiveUser } from '../../utils';
import { CloseButton } from '@chakra-ui/react';

const DashboardPage = () => {
  const [apiKeysList, setApiKeysList] = useState<ApiKeyType[]>([]);
  const [currentApiKey, setCurrentApiKey] = useState<ApiKeyType>(null);
  const { getApiKeys } = useApiKeys();
  const [isSavingApiKey, setIsSavingApiKey] = useState(false);
  const [isDeletingApiKey, setIsDeletingApiKey] = useState(false);
  const [keyAction, setKeyAction] = useState<'' | 'create' | 'edit'>('');
  const [isCreateOrEditAPIKeyOpen, setIsCreateOrEditAPIKeyOpen] =
    useState(false);
  const [isConfirmDeleteAPIKeyOpen, setIsConfirmDeleteAPIKeyOpen] =
    useState(false);

  const { user, session } = Auth.useUser();
  const toast = useToast();

  // CREATE API KEY
  const createAPIKey = useCallback(
    async (userId: string, apiKey: ApiKeyType) => {
      const newHash = getHash(user.id);
      const { name } = apiKey;

      try {
        setIsSavingApiKey(true);
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_KEY_ENDPOINT}`,
          {
            userId: userId,
            keyName: name,
            hash: newHash,
          }
        );

        setApiKeysList((prev) => [data, ...prev]);
        onCloseCreateTokenModal();
        setCurrentApiKey(null);
        setIsSavingApiKey(false);
        toast({
          title: 'New API key created successfully!',
          position: 'bottom-left',
          isClosable: true,
          status: 'success',
        });
      } catch (error) {
        console.log('error', error);
        setIsSavingApiKey(false);
        if (error.response.data.message) {
          toast({
            title: 'Failed to create API key',
            description: error.response.data.message,
            position: 'bottom-left',
            isClosable: true,
            status: 'error',
          });
        } else {
          toast({
            title: 'Failed to create API key',
            description: `Something went wrong!`,
            position: 'bottom-left',
            isClosable: true,
            status: 'error',
          });
        }
      }
    },
    []
  );

  //DELETE API KEYS

  const deleteAPIkey = useCallback(
    async (apiKey: ApiKeyType) => {
      const { id } = apiKey;
      const newHash = getHash(`${id}${user.id}`);
      try {
        setIsDeletingApiKey(true);
        const res = await axios({
          method: 'DELETE',
          url: `${process.env.REACT_APP_API_KEY_ENDPOINT}`,
          data: {
            apiKeyIdentifier: id,
            userId: user.id,
            hash: newHash,
          },
        });

        const { keys } = await getApiKeys(user.id);

        // setApiKeysList([...keys]);
        onCloseConfirmDeleteAPIKey();
        setCurrentApiKey(null);
        setIsDeletingApiKey(false);
        toast({
          title: 'API key deleted successfully!',
          position: 'bottom-left',
          isClosable: true,
          status: 'success',
        });
      } catch (error) {
        setIsDeletingApiKey(false);
        toast({
          title: 'Failed to delete API key',
          description: 'Something went wrong!',
          position: 'bottom-left',
          isClosable: true,
          status: 'error',
        });
      }
    },
    [toast]
  );

  // ##### OLD API CODE #### //
  const oldcreateAPIKey = useCallback(
    async (userId: string, apiKey: definitions['api_keys']) => {
      try {
        setIsSavingApiKey(true);
        const { name } = apiKey;
        const { data } = await supabase
          .from('api_keys')
          .select('name')
          .eq('user_id', userId);

        // Fetching all apis keys in user scope
        const apiKeys = data.map((item) => item.name);
        if (apiKeys.includes(name)) {
          toast({
            title: 'Failed to create API key',
            description: `API key name "${name}" already exists`,
            position: 'bottom-left',
            isClosable: true,
            status: 'error',
          });
          setIsSavingApiKey(false);
          return;
        }
        const newApiKey = await supabase
          .from('api_keys')
          .insert({ name, user_id: userId });
        // fetch all api key names from superbase for this user id
        if (newApiKey.error) {
          toast({
            title: 'Failed to create API key',
            description: newApiKey.error.message,
            position: 'bottom-left',
            isClosable: true,
            status: 'error',
          });
          // @ts-ignore: 'newApiKey.data' is possibly 'null'.
        } else if (newApiKey?.data?.length) {
          toast({
            title: 'New API key created successfully!',
            position: 'bottom-left',
            isClosable: true,
            status: 'success',
          });
          // @ts-ignore: 'newApiKey.data' is possibly 'null'.
          setApiKeysList((prev) => [...prev, newApiKey.data[0]]);
          onCloseCreateTokenModal();
          setCurrentApiKey(null);
        }
        setIsSavingApiKey(false);
      } catch (error) {
        setIsSavingApiKey(false);
      }
    },
    [toast]
  );

  const olddeleteAPIkey = useCallback(
    async (apiKey: definitions['api_keys']) => {
      try {
        setIsDeletingApiKey(true);
        const response = await supabase
          .from('api_keys')
          .delete()
          .match({ id: apiKey.id });
        if (response.error) {
          toast({
            title: 'Failed to delete API key',
            description: response.error.message,
            position: 'bottom-left',
            isClosable: true,
            status: 'error',
          });
        } else {
          toast({
            title: 'API key deleted successfully!',
            position: 'bottom-left',
            isClosable: true,
            status: 'success',
          });
          setApiKeysList((prev) => prev?.filter((key) => key.id !== apiKey.id));
          onCloseConfirmDeleteAPIKey();
          setCurrentApiKey(null);
        }
        setIsDeletingApiKey(false);
      } catch (error) {
        setIsDeletingApiKey(false);
      }
    },
    [toast]
  );

  // ##### OLD API CODE #### //

  /**
   * Create Or Edit API Key event handlers
   */
  const onCloseCreateTokenModal = () => setIsCreateOrEditAPIKeyOpen(false);

  const onOpenCreateTokenModal = () => {
    setKeyAction('create');
    setIsCreateOrEditAPIKeyOpen(true);
  };

  const onOpenEditTokenModal = (apiKey: ApiKeyType) => {
    setCurrentApiKey(apiKey);
    setKeyAction('edit');
    setIsCreateOrEditAPIKeyOpen(true);
  };

  const onCreateOrUpdateApiKey = async () => {
    if (keyAction === 'create') {
      await createAPIKey(user?.id, currentApiKey);
    }
  };

  /**
   * Confirm delete API key event handlers
   */
  const onCloseConfirmDeleteAPIKey = () => setIsConfirmDeleteAPIKeyOpen(false);

  const onOpenConfirmDeleteAPIKey = (apiKey: ApiKeyType) => {
    setIsConfirmDeleteAPIKeyOpen(true);
    setCurrentApiKey(apiKey);
  };

  const onConfirmDeleteApiKey = async () => {
    await deleteAPIkey(currentApiKey);
  };

  const [activeUsers, setActiveUsers] = useState([]);
  const [activeUser, setActiveUser] = useState<ActiveUser>({ email: user?.email, id: user?.id });

  useEffect(() => {
      const fetchActiveUsers = async () => {
        try {
          const accessToken = session.access_token;
          const response = await fetch(`https://u.supernova-493.workers.dev/api/active-users`,
            {
              headers: {
                Authorization: accessToken,
              },
            }
          );
          const data = await response.json();
          const formattedUsers = data.userEmails.map((user, index) => ({
            value: user.id,
            label: user.email,
          }));
          setActiveUsers(formattedUsers);
        } catch (error) {
          console.error("Error fetching active users:", error);
        }
      };
    if (GOD_MODE_USER_EMAILS.includes(user?.email)) {
      fetchActiveUsers();
    }
  }, []);

  const handleUserChange = (selected) => {
    setActiveUser({ email: selected.label, id: selected.value });
  };

  return (
    <>
      <SEO
        title="Dashboard"
        htmlAttributes={{ lang: 'en' }}
        link={[
          {
            rel: 'canonical',
            href: `${baseUrl}/dashboard`,
          },
        ]}
        meta={[{ content: 'Dashboard', property: 'og:title' }]}
      />

      {isCreateOrEditAPIKeyOpen && (
        <CreateOrEditAPIKey
          keyAction={keyAction}
          isSavingApiKey={isSavingApiKey}
          currentApiKey={currentApiKey}
          open={isCreateOrEditAPIKeyOpen}
          onConfirm={onCreateOrUpdateApiKey}
          setCurrentApiKey={setCurrentApiKey}
          onClose={onCloseCreateTokenModal}
        />
      )}

      {isConfirmDeleteAPIKeyOpen && (
        <ConfirmDeleteKey
          open={isConfirmDeleteAPIKeyOpen}
          isDeletingApiKey={isDeletingApiKey}
          onClose={onCloseConfirmDeleteAPIKey}
          onConfirm={onConfirmDeleteApiKey}
        />
      )}


      <DashboardLayout>
      {GOD_MODE_USER_EMAILS.includes(user?.email) && (
        <Box
        position="fixed"
        height="60px"
        width="100%"
        backgroundColor="white"
        zIndex={5}
        top="5rem"
        left="0"
      >
        <Box
          position="absolute"
          right={['1rem', '2rem', '4rem']}
          width="300px"
        >
          <Select
            options={activeUsers}
            value={activeUser?.email}
            onChange={handleUserChange}
            placeholder="Search or select a user to mock"
            isSearchable
          />
        </Box>
      </Box>
      )}
        {GOD_MODE_USER_EMAILS.includes(user?.email) && !GOD_MODE_USER_EMAILS.includes(activeUser?.email) && (
          <Box
            position="fixed"
            zIndex={5}
            bottom="0"
            left="0"
            color="white"
            width="100vw"
            backgroundColor="orange-soda"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderColor="orange-soda"
            mt="6rem"
            py="0.5rem"
            px="1rem"
            mx="auto"
            textAlign="center"
          >
            <Text>
              <strong>Mock mode enabled</strong>
            </Text>
            <Text fontSize="sm">
              You're now mocking: <strong>{activeUser?.email}</strong>
            </Text>
            <CloseButton
              bg="white"
              color="orange-soda"
              borderRadius= "sm"
              px="2rem"
              fontSize="sm"
              onClick={() => setActiveUser({ email: user?.email, id: user?.id })}
              _hover={{
                bg: "orange-soda",
                color: "white",
                borderColor: "white",
                borderWidth: "1px",
                borderRadius: "sm"
              }}
            >
              Exit
            </CloseButton>
          </Box>
        )}
        <Heading
            size="lg"
            mt={GOD_MODE_USER_EMAILS.includes(user?.email) && activeUser?.email ? '6rem' : ['1rem', '2rem', '4rem']}
            mb={"0.5rem"}
          >
          Dashboard
        </Heading>

        <Usage showDetails={true} activeUser={activeUser}/>
        <Credit showDetails={true} activeUser={activeUser}/>
        <APIKey
          activeUser={activeUser}
          onOpenCreateTokenModal={onOpenCreateTokenModal}
          apiKeysList={apiKeysList}
          setApiKeysList={setApiKeysList}
          onOpenEditTokenModal={onOpenEditTokenModal}
          onOpenConfirmDeleteAPIKey={onOpenConfirmDeleteAPIKey}
        />
      </DashboardLayout>
    </>
  );
};

export default withAuth(DashboardPage);
