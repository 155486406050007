import { Button, Checkbox, Spinner, Stack } from '@chakra-ui/react';
import { Flex, Heading, Text } from '@chakra-ui/layout';
import Card from '../../components/Card';
import React, { useEffect, useState } from 'react';
import SEO from '../../components/SEO';
import { baseUrl, useAuth } from '../../context/Auth';
import { Profile } from '../../utils';
import { Session, User } from '@supabase/supabase-js';

import { supabase } from '../../supabaseClient';
import AccountSettingsLayout from './AccountSettingsLayout';

import withAuth from '../Auth/withAuth';

interface InitialState {
  monthly_report_email: boolean;
  plan_ended_email: boolean;
  plan_ending_soon_email: boolean;
  unlimited_credits_email?: boolean;
}

const EmailNotifications = () => {
  const [user, setUser] = useState<Profile>(null);

  const [unlimitedUsage, setUnlimitedUsage] = useState(false);
  const [unsubscriptionLabel, setUnsubscriptionLabel] =
    useState<InitialState | null>(null);
  const [status, setStatus] = useState({
    loading: false,
    error: '',
  });
  const [loading, setLoading] = useState({
    unsubscribeType: '',
  });

  useEffect(() => {
    (async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUser(session?.user ?? null);
    })();
  }, []);

  useEffect(() => {
    if (user) {
      (async () => {
        setStatus({
          error: '',
          loading: true,
        });
        const { data, error } = await supabase
          .from('user_profiles')
          .select()
          .eq('id', user.id);
        const res = await supabase
          .from('user_stripe')
          .select()
          .eq('id', user.id);

        if (data)
          setUnsubscriptionLabel((prev) => ({
            ...prev,
            monthly_report_email: data[0]?.monthly_report_email,
            plan_ended_email: data[0]?.plan_ended_email,
            plan_ending_soon_email: data[0]?.plan_ending_soon_email,
            unlimited_credits_email: data[0]?.unlimited_credits_email,
          }));
        setUnlimitedUsage(res.data[0].unlimited_usage);
        setStatus({
          error: error?.message,
          loading: false,
        });
      })();
    } else {
      setStatus({
        error: '',
        loading: true,
      });
    }
  }, [user?.id]);

  const handleEmailCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnsubscriptionLabel({
      ...unsubscriptionLabel,
      [e.target.name]: e.target.checked,
    });
  };

  const handleUnsubscribe = async (unsubscribeType: string) => {
    setLoading({ unsubscribeType: unsubscribeType });
    // Call api
    if (unsubscribeType === 'individual-emails') {
      const { error } = await supabase
        .from('user_profiles')
        .update(unsubscriptionLabel)
        .match({ id: user.id });

      if (error) {
        setStatus({
          loading: false,
          error: error.message,
        });
      }
    } else {
      const payload = {
        monthly_report_email: false,
        plan_ended_email: false,
        plan_ending_soon_email: false,
        unlimited_credits_email: false,
      };
      setUnsubscriptionLabel(payload);
      const { error } = await supabase
        .from('user_profiles')
        .update(payload)
        .match({ id: user.id });
      if (error) {
        setStatus({
          loading: false,
          error: error.message,
        });
      }
    }

    setLoading({ unsubscribeType: '' });
  };

  return (
    <>
      <SEO
        title="Email notifications"
        htmlAttributes={{ lang: 'en' }}
        link={[
          {
            rel: 'canonical',
            href: `${baseUrl}/notifications`,
          },
        ]}
        meta={[
          {
            content: 'Account settings - Email notifications',
            property: 'og:title',
          },
        ]}
      />
      <AccountSettingsLayout page="notifications">
        <Card py="4rem" flexGrow={1}>
          <Heading textAlign="center" fontSize="3xl" mb="3rem" mx="auto">
            Email Notifications
          </Heading>
          <Flex as="form" mx="auto" maxW="500px" flexDir="column">
            <Text mb="32px" fontSize="16px">
              Send me emails when:
            </Text>
            {status.loading ? (
              <Flex justify="center" align="center" h="100%" p={8}>
                <Spinner size="xl" />
              </Flex>
            ) : (
              <>
                <Stack spacing={[0, 4]}>
                  <Checkbox
                    onChange={handleEmailCheck}
                    fontSize="16px"
                    name="monthly_report_email"
                    isChecked={unsubscriptionLabel?.monthly_report_email}
                  >
                    End of monthly cycle
                  </Checkbox>
                  <Checkbox
                    fontSize="16px"
                    onChange={handleEmailCheck}
                    name="plan_ended_email"
                    isChecked={unsubscriptionLabel?.plan_ended_email}
                  >
                    Credits consumed
                  </Checkbox>
                  <Checkbox
                    fontSize="16px"
                    onChange={handleEmailCheck}
                    name="plan_ending_soon_email"
                    isChecked={unsubscriptionLabel?.plan_ending_soon_email}
                  >
                    80% credits consumed
                  </Checkbox>
                  {unlimitedUsage && (
                    <Checkbox
                      fontSize="16px"
                      onChange={handleEmailCheck}
                      name="unlimited_credits_email"
                      isChecked={unsubscriptionLabel?.unlimited_credits_email}
                    >
                      Unlimited credits are awarded
                    </Checkbox>
                  )}
                </Stack>
                {/* <Flex mt="40px" justifyContent={'end'}> */}
                <Button
                  py="1.5rem"
                  color="white"
                  width={'full'}
                  mt="40px"
                  fontFamily="heading"
                  bg="orange-soda"
                  fontSize={'18px'}
                  _hover={{ bg: 'orange-soda' }}
                  rounded="3px"
                  onClick={() => handleUnsubscribe('individual-emails')}
                  isLoading={loading.unsubscribeType === 'individual-emails'}
                >
                  Save Changes
                </Button>
                {status.error && (
                  <Text mt="10px" color="red" fontSize={'12px'}>
                    {status.error}
                  </Text>
                )}
                {/* </Flex> */}
                <Button
                  color={'#006CFF'}
                  background={'none'}
                  textAlign="start"
                  fontFamily="heading"
                  _hover={{ bg: 'none' }}
                  marginTop={'18px'}
                  px="0px"
                  onClick={() => handleUnsubscribe('unsubscribe')}
                  isLoading={loading.unsubscribeType === 'unsubscribe'}
                >
                  Unsubscribe from all emails
                </Button>
              </>
            )}
          </Flex>
        </Card>
      </AccountSettingsLayout>
    </>
  );
};

export default withAuth(EmailNotifications);
