import React from 'react';
import { Helmet } from 'react-helmet';

type Meta = {
  name?: string;
  content?: string;
  property?: string;
};

interface Props {
  htmlAttributes?: {
    [key: string]: string | number | boolean | null | undefined;
  };
  link?: JSX.IntrinsicElements['link'][] | undefined;
  defaultTitle?: string;
  title: string;
  meta?: Meta[];
}

const SEO = ({
  htmlAttributes,
  title,
  meta = [],
  defaultTitle,
  link,
}: Props) => {
  return (
    <Helmet
      link={link}
      title={title}
      htmlAttributes={htmlAttributes}
      meta={meta}
      defaultTitle={defaultTitle}
    />
  );
};

export default SEO;
