import { Button } from '@chakra-ui/button';
import { Icon } from '@chakra-ui/icon';
import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/input';
import React, { forwardRef, Ref } from 'react';
import { eyeClose, eyeOpen } from '../../utils/svgs';

const PasswordInput = forwardRef(
  (props: InputProps, ref: Ref<HTMLInputElement>) => {
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);

    return (
      <InputGroup size="md">
        <Input ref={ref} type={show ? 'text' : 'password'} {...props} />
        <InputRightElement width="3rem">
          <Button
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            _focus={{ outline: 'none' }}
            h="1.75rem"
            size="sm"
            onClick={handleClick}
          >
            {show ? (
              <Icon w="20px" fill="gray.100" as={() => eyeOpen} />
            ) : (
              <Icon w="20px" fill="gray.100" as={() => eyeClose} />
            )}
          </Button>
        </InputRightElement>
      </InputGroup>
    );
  }
);

export default PasswordInput;
